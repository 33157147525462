import Vue from "vue";
import Vuex from "vuex";
import { Store } from "vuex";
Vue.use(Vuex);
import { commonService } from "@/services/common.service";
import home from "./modules/home";
import chart from "./modules/chart";
import header from "./modules/headers";
import funds from "./modules/funds";
import login from "./modules/login";
import pref from "./modules/client-utils";
import router from "@/router/index";
import marketWatch from "./modules/marketWatch";
import oc from "./modules/optionchain.js";
import gtt from "./modules/gtt";
import order from "./modules/order";
import orderWindow from "./modules/orderWinow";
import position from "./modules/position";
import holdings from "./modules/holding";
import basket from "./modules/basket";
import alert from "./modules/alert";
import common from "./Services/common";

import appsStore from "./modules/apps";
// ws
import wsConnection from "./modules/websocket";

// images
import loginLogo from "../assets/images/applogo.svg";
import errorHandling from "../handleError/errHandling.js";
import { env } from "@/helpers/env";
let internetCounter = 0;

export default new Vuex.Store({
  state: {
    env: env().env,
    appName: "Goodwill",
    buttonThemeColor: "#3977DE",
    loginLogo: loginLogo,
    projectName: "Goodwill",
    projectLink: "Goodwill",
    apiDocumentaionLink: "",
    counter: 0,
    sessionCounter: 0,
    fundsOrHoldingsLoader: false,
    isMobileView: false,
    isLapView: false,
    basketResponsive: false,
    windowHeight: "",
    windowWidth: "",
    createAccount: "https://ekyc.gwcindia.in/client/",
    chartFeed: "",
    currentChartData: "",
    iconBlackColourCode: "#282828",
    iconLightColourCode: "#BEBEBE",
    amogaColor: "#2297D4",
    iconGrayColor: "#7B7B7B",
    hoverIconColor: "#0075E1",
    hoverIconBlackColor: "#200E32",
    isHaveInternet: false,
    symbolInfo: "",
    basketCount: 0,
    selectedItemDynamic: 0,
    lastBarValue: 0,
    vendorDetails: "",
    currentAppCode: "",
    loader: false,
    selectedFont: "s",
    globalLoader: false,
    positiontoaster: "bottom-right",
    botAppCode: "mGy0mgshPXddK61",
    mutualAppcode: "eEIaxt4I1LLLdgR",
    fontSize: "s",
    optionAuthCode: "hpEDnsqYJ5i4Igs",
    onlyOptionsAuthCode: "oIEVWdKO5H1L0GT",
    stickyOrderCondition: false,
    validateAuthCode: "",
    tradeChartData: "",
    selectedTheme: "light",
    isTechnical: false,
    technicalUrl: "",
    isRiskDialog: false,
    popDialogData: [],

    isNotiDialog: false,
    defaultQueries: {
      alerts: {
        query: { tab: "Alerts" },
      },
      optionchain: {
        query: { tab: "" },
      },
      home: {
        query: { tab: "Investment", sTab1: "Stocks", sTab2: "All" },
      },
      orders: {
        query: { tab: "Pending" },
      },
      positions: {
        query: { tab: "All" },
      },
      holdings: {
        query: { tab: "Stocks" },
      },
      transactions: {
        query: { tab: "Payin" },
      },
      settings: {
        query: { tab: "General" },
      },
      funds: {
        query: { tab: "Balance Breakup" },
      },
      apps: {
        query: { tab: "Apps" },
      },
      chart: {
        query: { tab: "" },
      },
      equity: {
        query: { tab: "Gainers" },
      },
      etf: {
        query: { tab: "Index Based" },
      },
      futures: {
        query: { tab: "Index" },
      },
      exchange: {
        query: { tab: "Gainers" },
      },
      marketwatch: {
        query: { tab: "" },
      },
    },
    queries: {
      alerts: {
        query: { tab: "Alerts" },
      },
      optionchain: {
        query: { tab: "" },
      },
      marketwatch: {
        query: { tab: "" },
      },
      home: {
        query: { tab: "Investment", sTab1: "Stocks", sTab2: "All" },
      },
      funds: {
        query: { tab: "Balance Breakup" },
      },
      orders: {
        query: { tab: "Pending" },
      },
      settings: {
        query: { tab: "General" },
      },
      positions: {
        query: { tab: "All" },
      },
      holdings: {
        query: { tab: "Stocks" },
      },
      transactions: {
        query: { tab: "Payin" },
      },
      apps: {
        query: { tab: "Apps" },
      },
      chart: {
        query: { tab: "" },
      },
      equity: {
        query: { tab: "Gainers" },
      },
      etf: {
        query: { tab: "Index Based" },
      },
      futures: {
        query: { tab: "Index" },
      },
      exchange: {
        query: { tab: "Gainers" },
      },
    },
    activePageTab: "Pending",
    equityTabList: [
      { name: "Gainers", value: "Gainers", count: 0 },
      { name: "Losers", value: "Losers", count: 0 },
      { name: "52-W High", value: "52-W High", count: 0 },
      { name: "52-W Low", value: "52-W Low", count: 0 },
    ],
    etfTabList: ["Index Based", "Debt", "Power"],
    futuresTabList: ["Index", "Nifty Liquid", "Bank Nifty"],
    dashboardTabList: [{ name: "Investment", value: "Investment", count: 0 }],

    holdingsTabList: [{ name: "Stocks", value: "Stocks", count: 0 }],
    orderTabList: [
      { name: "Pending", value: "Pending", count: 0 },
      { name: "Executed", value: "Order Book", count: 0 },
      { name: "Tradebook", value: "Trade Book", count: 0 },
      { name: "Basket", value: "Basket", count: 0 },
      { name: "GTT", value: "GTT", count: 0 },
      { name: "Alerts", value: "Alerts", count: 0 },
    ],
    notiAlertTabList: [{ name: "Alerts", value: "Alerts", count: 0 }],
    positionTabList: [
      { name: "All", value: "All", count: 0 },
      { name: "F&O", value: "F&O", count: 0 },
      { name: "MCX", value: "MCX", count: 0 },
    ],
    week52List: ["High", "Low"],
    fundsTablist: [
      { name: "Balance Breakup", value: "Balance Breakup", count: 0 },
    ],
    fundamentalTablist: ["Technicals", "Fundamentals"],
    notifyContent: [],
    dialogType: "",
    notificationList: [],
    preferenceTheme: false,
    preferenceWatch: "0",
    dialogCondition: false,
    feedbackCondition: false,
    apiGenerationType: false,
    isNodifyDialog: false,
    isAuthDialog: false,
    currentAlertId: 0,
    isAuthorizeDialog: false,
    transactionTabList: ["Payin", "Payout"],
    appsTabList: [
      { name: "Apps", value: "Apps", count: 0 },
      { name: "API Key", value: "API Key", count: 0 },
    ],
    settingsTabList: [
      { name: "General", value: "General", count: 0 },
      { name: "Security", value: "Security", count: 0 },
      { name: "Markets", value: "Markets", count: 0 },
      { name: "Version", value: "Version", count: 0 },
    ],
    builddate: "31_03_2025",
    buildName: "31_03_2025_18_40",
    buildVersion: "1.0.0.7",
  },
  mutations: {
    resetState(state, payload) {
      state.counter = 0;
      state.sessionCounter = 0;
      state.fundsOrHoldingsLoader = false;
      state.isMobileView = false;
      state.isLapView = false;
      state.basketResponsive = false;
      state.windowHeight = "";
      state.chartFeed = "";
      state.currentChartData = "";

      state.isHaveInternet = false;
      state.symbolInfo = "";
      state.basketCount = 0;
      state.selectedItemDynamic = 0;
      state.lastBarValue = 0;
      state.vendorDetails = "";
      state.currentAppCode = "";
      state.loader = false;
      state.globalLoader = false;

      state.stickyOrderCondition = false;
      state.validateAuthCode = "";
      state.tradeChartData = "";
      state.selectedTheme = "light";
      state.isTechnical = false;
      state.technicalUrl = "";
      state.isRiskDialog = false;
      state.popDialogData = [];

      state.isNotiDialog = false;
      state.defaultQueries = {
        optionchain: {
          query: { tab: "" },
        },
        home: {
          query: { tab: "Investment", sTab1: "Stocks", sTab2: "All" },
        },
        orders: {
          query: { tab: "Pending" },
        },
        positions: {
          query: { tab: "All" },
        },
        holdings: {
          query: { tab: "Stocks" },
        },
        transactions: {
          query: { tab: "Payin" },
        },
        settings: {
          query: { tab: "General" },
        },
        funds: {
          query: { tab: "Balance Breakup" },
        },
        apps: {
          query: { tab: "Apps" },
        },
        chart: {
          query: { tab: "" },
        },
        equity: {
          query: { tab: "Gainers" },
        },
        etf: {
          query: { tab: "Index Based" },
        },
        futures: {
          query: { tab: "Index" },
        },
        exchange: {
          query: { tab: "Gainers" },
        },
        marketwatch: {
          query: { tab: "" },
        },
      };
      state.queries = {
        optionchain: {
          query: { tab: "" },
        },
        marketwatch: {
          query: { tab: "" },
        },
        home: {
          query: { tab: "Investment", sTab1: "Stocks", sTab2: "All" },
        },
        funds: {
          query: { tab: "Balance Breakup" },
        },
        orders: {
          query: { tab: "Pending" },
        },
        settings: {
          query: { tab: "General" },
        },
        positions: {
          query: { tab: "All" },
        },
        holdings: {
          query: { tab: "Stocks" },
        },
        transactions: {
          query: { tab: "Payin" },
        },
        apps: {
          query: { tab: "Apps" },
        },
        chart: {
          query: { tab: "" },
        },
        equity: {
          query: { tab: "Gainers" },
        },
        etf: {
          query: { tab: "Index Based" },
        },
        futures: {
          query: { tab: "Index" },
        },
        exchange: {
          query: { tab: "Gainers" },
        },
      };
      state.activePageTab = "Pending";

      state.holdingsTabList = [{ name: "Stocks", value: "Stocks", count: 0 }];
      state.orderTabList = [
        { name: "Pending", value: "Pending", count: 0 },
        { name: "Executed", value: "Order Book", count: 0 },
        { name: "Tradebook", value: "Trade Book", count: 0 },
        { name: "Basket", value: "Basket", count: 0 },
        { name: "GTT", value: "GTT", count: 0 },
        { name: "Alerts", value: "Alerts", count: 0 },
      ];
      state.positionTabList = [
        { name: "All", value: "All", count: 0 },
        { name: "F&O", value: "F&O", count: 0 },
        { name: "MCX", value: "MCX", count: 0 },
      ];
      state.week52List = ["High", "Low"];

      state.notifyContent = [];
      state.dialogType = "";
      state.preferenceTheme = false;
      state.preferenceWatch = "0";
      state.dialogCondition = false;
      state.feedbackCondition = false;
      state.apiGenerationType = false;
      state.isNodifyDialog = false;
      state.isAuthDialog = false;
      state.currentAlertId = 0;
      state.isAuthorizeDialog = false;
    },
    setGlobalFont(state, payload) {
      state.fontSize = payload;
      localStorage.setItem("font", payload);
    },
    setthmValue(state, payload) {
      state.preferenceTheme = payload == "0" ? false : true;
      state.selectedTheme = payload == 0 ? "light" : "dark";
      localStorage.setItem("chartTheme", JSON.stringify(payload));
    },
    settktValue(state, payload) {},
    setmwoValue(state, payload) {},
    setsowValue(state, payload) {},
    setmwpValue(state, payload) {},
    setfplValue(state, payload) {},
    setowtValue(state, payload) {},
    setmwtValue(state, payload) {
      state.preferenceWatch = payload;
    },
    setn50Value(state, payload) {},
    setbnfValue(state, payload) {},
    setsnxValue(state, payload) {},
    setdepValue(state, payload) {},
    setSelectedItem(state, payload) {
      state.selectedItemDynamic = payload; //
    },
    setFeedbackCondition(state, payload) {
      state.feedbackCondition = payload;
    },
    setApiGenerationType(state, payload) {
      state.apiGenerationType = payload;
    },
    setDialogCondition(state, payload) {
      state.dialogType = payload.type;
      state.dialogCondition = payload.condition;
      payload.alertId ? (state.currentAlertId = payload.alertId) : "";
    },
    setQueryTab(state, payload) {
      state.queries.exchange.query.tab = payload;
    },
    setActivePageTab(state, payload) {
      state.activePageTab = payload;
    },
    //
    setQueries(state, payload) {
      if (payload) {
        let tempQuries = state.queries;
        let path = router.currentRoute.path.toString().replace("/", "");

        if (payload.action == "intial" || payload.action == "override") {
          tempQuries = payload.data;
        } else {
          if (path && tempQuries[path]) {
            payload.data.tab
              ? (tempQuries[path].query.tab = payload.data.tab)
              : "";
            payload.data.sTab1
              ? (tempQuries[path].query.sTab1 = payload.data.sTab1)
              : "";
            payload.data.sTab2
              ? (tempQuries[path].query.sTab2 = payload.data.sTab2)
              : "";
          }
        }
        state.queries = tempQuries;

        localStorage.setItem("gwTabQueries", JSON.stringify(tempQuries));

        let queryName = "";

        if (path) {
          queryName = path;
        } else {
          queryName = "home";
        }

        state.queries[queryName] && state.queries[queryName].query
          ? this.commit("setActivePageTab", state.queries[queryName].query.tab)
          : "";
      }
    },

    setCounterIncrease(state, data) {
      state.counter = data;
    },
    setSessionCounter(state, payload) {
      state.sessionCounter = payload;
    },
    setLoader(state, payload) {
      state.fundsOrHoldingsLoader = payload;
    },
    setGlobalLoader(state, payload) {
      state.loader = payload;
    },
    setChartFeed(state, payload) {
      state.chartFeed = payload;
    },
    setChartData(state, payload) {
      state.currentChartData = payload;
      localStorage.setItem("chartData", JSON.stringify(payload));
    },
    setSymbolInfo(state, payload) {
      state.symbolInfo = payload;
    },
    setBasketCount(state, payload) {
      state.basketCount = payload;
    },
    setLastBarValue(state, payload) {
      state.lastBarValue = payload;
    },
    setGlobalLoader(state, payload) {
      state.globalLoader = payload;
    },
    setStickyOrder(state, payload) {
      state.stickyOrderCondition = payload;
      localStorage.setItem("isSticky", JSON.stringify(payload));
    },
    setValidateAuthCode(state, payload) {
      state.validateAuthCode = payload;
    },

    setTechnicalUrl(state, payload) {
      state.technicalUrl = payload;
    },
    setisTechnical(state, payload) {
      state.isTechnical = payload;
    },
    setIsRiskDialog(state, payload) {
      state.isRiskDialog = payload;
      localStorage.setItem("isRiskDialog", JSON.stringify(payload));
    },
    setPopDialogData(state, payload) {
      state.popDialogData = payload;
    },
    setVendorDetails(state, payload) {
      state.vendorDetails = payload;
    },
    setCurrentAppcode(state, payload) {
      state.currentAppCode = payload;
    },
    setIsAuthDialog(state, payload) {
      state.isAuthorizeDialog = payload;
    },
    setNotificationDetails(state, payload) {
      state.notificationList = payload;
    },
  },
  actions: {
    toaster({ state }, payload) {
      state.isNotiDialog = payload;

      setTimeout(() => {
        state.isNotiDialog = false;
      }, 2000);
    },
    setMobileView({ state }, payload) {
      state.isMobileView = payload;
    },
    async getPreference({ state, commit }, payload) {
      await commonService.getPreference().then(
        (res) => {
          if (res.status == 200 && res.data.status == "Ok") {
            let preference = res.data.result;
            preference.forEach((el) => {
              if (this._mutations[`set${el.tag}Value`]) {
                commit(`set${el.tag}Value`, el.value);
              }
            });
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      );
    },
    async updatePreference({ state, commit }, payload) {
      await commonService.updatePreference(payload).then((res) => {});
    },
    async submitFeedback(state, payload) {
      await commonService.submitFeedback(payload).then(
        (res) => {},
        (err) => {
          errorHandling.errLog(err);
        }
      );
    },
    setLapView({ state }, payload) {
      state.isLapView = payload;
    },
    setBasketResponsive({ state }, payload) {
      state.basketResponsive = payload;
    },
    setWindowInnerHeight({ state }, payload) {
      state.windowHeight = payload;
    },
    setWindowInnerWidth({ state }, payload) {
      state.windowWidth = payload;
    },
    setConnectivity({ state }, payload) {
      state.isHaveInternet = navigator.onLine;
      if (!navigator.onLine && internetCounter == 0) {
        errorHandling.toaster(
          "Please check your internet connectivity!",
          "danger",
          "",
          5000,
          "bottom-right",
          "",
          ""
        );
        internetCounter = 1;
      } else if (navigator.onLine) {
        internetCounter = 0;
      }
    },
    setTechnicalDialog(state, payload) {
      state.technicalUrl = payload;
    },
    callNotifyPop({ state, commit, dispatch, rootGetters }, payload) {
      let json = {
        userId: rootGetters["login/getCurrentUserId"],
      };
      commonService
        .getPopContent(json)
        .then(
          (resp) => {
            let respResult = [];
            if (
              resp.status == 200 &&
              resp.data.status == "Ok" &&
              resp.data.message == "Success" &&
              resp.data.result &&
              resp.data.result?.length > 0
            ) {
              respResult = [...respResult, ...resp.data.result];
            }

            commit("setPopDialogData", respResult);
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {
          commit(
            "setIsRiskDialog",
            state.popDialogData?.length > 0 ? true : false
          );
        });
    },
    async getNotification({ state, commit, rootState }) {
      let json = {
        userId: rootState.login.UCC,
      };
      await commonService.getNotification(json).then(
        (res) => {
          if (
            res.status == 200 &&
            res.data.status == "Ok" &&
            res.data.message == "Success" &&
            res.data.result &&
            res.data.result.length > 0
          ) {
            commit("setNotificationDetails", res.data.result);
          } else {
            commit("setNotificationDetails", []);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      );
    },
  },
  modules: {
    oc,
    home,
    chart,
    funds,
    header,
    order,
    login,
    pref,
    marketWatch,
    orderWindow,
    position,
    holdings,
    wsConnection,
    common,
    basket,
    alert,
    appsStore,
    gtt,
  },
  getters: {
    getChartfeed: (state) => {
      return state.chartFeed;
    },
    getChartData: (state) => {
      return state.currentChartData;
    },
    getSymbolInfo: (state) => {
      return state.symbolInfo;
    },
    getStrikyOrder: (state) => {
      return state.stickyOrder;
    },
    getisNoti: (state) => {
      return state.isNotiDialog;
    },
    getEnv: (state) => state.env,
  },
});
