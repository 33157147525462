<template>
  <div class="px-4">
    <div class="d-flex align-center mb-1">
      <div :id="`${tabName}_Text`" class="font-weight-bold primaryColor">
        {{ tabName }}
      </div>

      <div>
        <v-progress-circular
          class="ml-2"
          indeterminate
          id="universal_loader"
          v-if="
            exchangeLoader ||
            fundsLoader ||
            holdingLoader ||
            postionLoader ||
            ordersLoader ||
            basketLoader ||
            alertsLoader ||
            apiLoader ||
            prefLoader ||
            ocLoader
          "
          size="18"
          :width="2"
          color="blue"
        ></v-progress-circular>
      </div>
      <!---->
    </div>
    <div v-if="!isSearchBar" class="d-flex flex-wrap">
      <div
        v-if="$router.currentRoute.path != '/alerts'"
        v-for="(button, id) in buttonsList"
        :key="id"
        :id="`${button.name}_button_container`"
        :name="`${button.name}_button_container`"
        class="align-center py-1"
        @click="
          sendToParent(button.name);
          $store.commit('setQueries', {
            data: { tab: button.name },
            tagName: tabName,
            action: 'change',
          });
        "
      >
        <div id="order_label" class="font-weight-bold">
          <button
            :id="`${button.name}_tab_menu_button`"
            :name="`${button.name}_tab_menu_button`"
            type="button"
            :class="[
              activePageTab == button.name
                ? 'activeColor white--text'
                : 'black--text',
              getFontSize(12),
            ]"
            class="mr-2 rounded-sm text-center text-capitalize v-btn v-btn--has-bg theme--light v-size--default"
            aria-haspopup="true"
            aria-expanded="false"
            style="height: 26px; min-width: 50px"
          >
            <span
              class="v-btn__content"
              :id="`${button.name}_tab_menu_button_text`"
              :name="`${button.name}_tab_menu_button_text`"
            >
              {{ button.value }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <div id="option_chain_search_bar_container" v-else class="d-flex">
      <div style="width: 250px" class="mr-5">
        <v-autocomplete
          v-model="exchange"
          id="exchange_list_input_field"
          outlined
          dense
          :height="10"
          single-line
          :items="exchangeList"
          @click="changeExchange"
          class="funds"
          :class="getFontSize(12)"
          item-text="showValue"
          item-value="showValue"
        ></v-autocomplete>
      </div>
      <div style="width: 250px" class="mr-5">
        <v-autocomplete
          v-model="underlyingVal"
          id="underlying_list_input_field"
          outlined
          dense
          :height="10"
          single-line
          :items="underlyingList"
          @click="callExpiryList"
          class="funds"
          :class="getFontSize(12)"
          item-text="showValue"
          item-value="showValue"
        ></v-autocomplete>
      </div>
      <div style="width: 250px" class="mr-5">
        <v-autocomplete
          v-model="expiryVal"
          id="expiry_list_input_field"
          outlined
          dense
          height="20"
          single-line
          :items="expiryList"
          class="funds"
          :class="getFontSize(12)"
          item-text="showValue"
          item-value="showValue"
        ></v-autocomplete>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import commonFunctions from "@/mixins/commonFunctions";

export default {
  name: "tabMenu",
  data: () => ({
    loader: false,
    selectAccount: "",
    formattedBankDetails: ["A", "B"],
    accountErrorMessage: "",
  }),
  mixins: [commonFunctions],
  props: {
    buttonsList: Array,
    tabName: String,
    isSearchBar: Boolean,
  },

  computed: {
    ...mapState(["activePageTab"]),
    ...mapState("position", ["postionLoader"]),
    ...mapState("holdings", ["holdingLoader"]),
    ...mapState("home", ["exchangeLoader"]),
    ...mapState("funds", ["fundsLoader"]),
    ...mapState("order", ["ordersLoader"]),
    ...mapState("basket", ["basketLoader"]),
    ...mapState("alert", ["alertsLoader"]),
    ...mapState("appsStore", ["apiLoader"]),
    ...mapState("pref", ["prefLoader"]),
    ...mapState("oc", [
      "expiryList",
      "underlyingList",
      "ocSymbol",
      "ocExpiry",
      "ocLoader",
      "ocExchange",
      "exchangeList",
    ]),
    exchange: {
      get: function () {
        return this.ocExchange;
      },
      set: function (exch) {
        this.$store.dispatch("oc/getUnderlying", { exch: exch }).finally(() => {
          this.$store
            .dispatch("oc/getUnderlyingExp", {
              ins: this.underlyingVal,
              exch: exch,
            })
            .finally(() => {
              this.emitScroll();
            });
        });
        this.$store.commit("oc/setInsExp", { exch });
      },
    },

    underlyingVal: {
      get: function () {
        return this.ocSymbol;
      },
      set: function (ins) {
        this.$store.commit("oc/setInsExp", { ins });
        this.$store
          .dispatch("oc/getUnderlyingExp", {
            ins: ins,
            exch: this.exchange,
          })
          .finally(() => {
            this.emitScroll();
          });
      },
    },
    expiryVal: {
      get: function () {
        return this.ocExpiry;
      },
      set: function (exp) {
        this.$store.commit("oc/setInsExp", { exp });
        let json = {
          expiry: exp,
          interval: 10,
          underlying: this.ocSymbol,
          exch: this.exchange,
        };
        if (exp && this.ocSymbol && this.exchange != "") {
          this.$store.dispatch("oc/getOptionChain", json).finally(() => {
            this.emitScroll();
          });
        }
      },
    },
  },
  methods: {
    emitScroll() {
      this.$emit("dropdown-scroll", "yes");
    },
    sendToParent(action) {
      this.$emit("from-child", action);
    },
    changeExchange() {},
    callExpiryList() {},
  },
};
</script>
