import { env } from "../helpers/env";
import { getHeaders } from "../helpers/auth-header";
import axios from "axios";

const AXIOS = axios.create({
  baseURL: env().BASEURL,
});
//
const holdingModule = "ho-rest";
const positionModule = "po-rest";
const mwModule = "mw-rest";
const scripsModule = "scrips-rest";
const ordersModule = "od-rest";
const alertModule = "alerts-rest";
const basketModule = "bo-rest";
const clientModule = "client-rest";
const fundsModule = "funds-rest";
const brokerageModule = "BrokerageCalculationService";
const commonModule = "common";
const ocModule = "obrest";

export const commonService = {
  getAllSymbols,
  getBrokeageData,
  getUserSession,
  getMwList,
  getSearchList,
  deleteScripList,
  addScripList,
  getUserHoldings,
  getUserPositions,
  getContractInfo,
  getSectorDetails,
  getMargin,
  getOrderBook,
  getFundsData,
  getComFundsData,
  placeOrder,
  cancelOrder,
  modifyOrder,
  getUserPref,
  updateUserPref,
  addUserPref,
  getTradeBook,
  getTopSectors,
  getAlerts,
  createAlert,
  cancelAlert,
  modifyAlert,
  sortMwList,
  getETFDetails,
  getBasketData,
  getBasketScrips,
  createBasket,
  renameBasket,
  deleteBasket,
  addScripInBasket,
  deleteScripInBasket,
  inValidateSession,
  createWsSession,
  productConvertion,
  getProfileData,
  getOrderHistory,
  getTickerTapeList,
  getPredefinedMw,
  getUnderlying,

  getSecurityInfo,
  getBrokerageDetails,
  squreOffAll,
  getWsSession,
  getBasketMargin,
  executeBasket,
  resetBasketExeStatus,
  changePassword,
  getPinnedScrips,
  pinToHeader,
  getAuthorizeHoldings,
  initAuthorize,
  submitFeedback,
  exitBoCoPosition,
  getExchangeStatus,
  getExchangeMsg,
  getPopContent,
  getDepositDetails,
  makePayment,

  getApiKey,
  generateApiKey,
  regenerateApiKey,
  getAppDetails,
  getUserProfile,
  getVendorAccess,
  authorizeVendor,
  getTopGainers,
  getTopLosers,
  getFutData,
  get52WeekHigh,
  get52weeklow,
  getEtfData,
  verifyPayment,
  refreshBasket,
  createPayout,
  cancelPayout,
  getUnderlyingExp,
  getOptionChain,
  getEdis,
  updateScrIpInBasket,
  getPreference,
  updatePreference,
  getBrokerage,
  getGttOrderBook,
  executeGtt,
  modifyGtt,
  deleteGtt,
  getNotification,
};
function getNotification(payload) {
  return AXIOS.post(
    `adrest/com/msg/get`,
    payload,
    getHeaders(true, false, false)
  );
}

function deleteGtt(payload) {
  return AXIOS.post(
    `${ordersModule}/orders/gtt/cancel`,
    payload,
    getHeaders(true, false, false)
  );
}
function modifyGtt(payload) {
  return AXIOS.post(
    `${ordersModule}/orders/gtt/modify`,
    payload,
    getHeaders(true, false, false)
  );
}
function executeGtt(payload) {
  return AXIOS.post(
    `${ordersModule}/orders/gtt/execute`,
    payload,
    getHeaders(true, false, false)
  );
}
function getGttOrderBook() {
  return AXIOS.get(
    `${ordersModule}/info/gtt/orderbook`,
    getHeaders(true, false, false)
  );
}
function updatePreference(payload) {
  return AXIOS.post(
    `${clientModule}/preferences/web/update`,
    payload,
    getHeaders(true, false, false)
  );
}
function getPreference() {
  return AXIOS.get(
    `${clientModule}/preferences/web`,
    getHeaders(true, false, false)
  );
}
function getEdis() {
  return AXIOS.get(
    `${holdingModule}/edis/get/hstoken`,
    getHeaders(true, false, false)
  );
}
function getUnderlyingExp(payload) {
  return AXIOS.post(
    `${ocModule}/optionChain/getUnderlyingExp`,
    payload,
    getHeaders(true, false, false)
  );
}
function getUnderlying(payload) {
  return AXIOS.post(
    `${ocModule}/optionChain/getUnderlying`,
    payload,
    getHeaders(true, false, false)
  );
}
function getOptionChain(payload) {
  return AXIOS.post(
    `${ocModule}/optionChain/getOptionChain`,
    payload,
    getHeaders(true, false, false)
  );
}

function getFutData() {
  return AXIOS.get("cu/future", getHeaders(true, false, false));
}
function getEtfData() {
  return AXIOS.get("cu/etf", getHeaders(true, false, false));
}
function getTopGainers() {
  return AXIOS.get(`cu/analysis/topgainers`, getHeaders(true, false, false));
}
function getTopLosers() {
  return AXIOS.get(`cu/analysis/toplosers`, getHeaders(true, false, false));
}
function get52WeekHigh() {
  return AXIOS.get("cu/analysis/52weekhigh", getHeaders(true, false, false));
}
function get52weeklow() {
  return AXIOS.get("cu/analysis/52weeklow", getHeaders(true, false, false));
}

function authorizeVendor(payload) {
  return AXIOS.post(
    "auth/sso/vendor/authorize",
    payload,
    getHeaders(true, false, false)
  );
}
function getVendorAccess(payload) {
  return AXIOS.post(
    "auth/sso/vendor/authorize/check",
    payload,
    getHeaders(true, false, false)
  );
}
function getUserProfile() {
  return AXIOS.get(
    `${clientModule}/profile/getUser`,
    getHeaders(true, false, false)
  );
}
function getAppDetails(payload) {
  return AXIOS.post(
    "auth/sso/vendor/deatils",
    payload,
    getHeaders(true, false, false)
  );
}
function getUserSession(payload) {
  return AXIOS.post(`auth/auth/login`, payload, getHeaders(true, false, false));
}

function getAllSymbols(payload) {
  return AXIOS.post(
    `https://web.gwcindia.in/restpy/get_all_underlying`,
    payload,
    getHeaders(true, false, false)
  );
}

function getBrokeageData(payload) {
  return AXIOS.post(
    `https://web.gwcindia,in/broCal/BrokerageCalculationService/brokerage/calculateBrokerage`,
    payload,
    getHeaders(true, false, false)
  );
}

function getMwList(payload) {
  return AXIOS.post(
    `${mwModule}/advance/mw`,
    payload,
    getHeaders(true, false, false)
  );
}

function getSearchList(payload) {
  return AXIOS.post(
    `${scripsModule}/scrip/search`,
    payload,
    getHeaders(true, false, false)
  );
}

function deleteScripList(payload) {
  return AXIOS.post(
    `${mwModule}/advance/deletescrip`,
    payload,
    getHeaders(true, false, false)
  );
}

function addScripList(payload) {
  return AXIOS.post(
    `${mwModule}/advance/addscrip`,
    payload,
    getHeaders(true, false, false)
  );
}

function getUserHoldings() {
  return AXIOS.get(`${holdingModule}/holdings`, getHeaders(true, false, false));
}

function getUserPositions() {
  return AXIOS.get(
    `${positionModule}/positions`,
    getHeaders(true, false, false)
  );
}

function getContractInfo(payload) {
  return AXIOS.post(
    `${scripsModule}/scrip/contract/info`,
    payload,
    getHeaders(true, false, false)
  );
}

function getMargin(payload) {
  return AXIOS.post(
    `${ordersModule}/orders/getmargin`,
    payload,
    getHeaders(true, false, false)
  );
}

function getSectorDetails() {
  return AXIOS.get(`${commonModule}/eqsector`, getHeaders(true, false, false));
}

function placeOrder(payload) {
  return AXIOS.post(
    `${ordersModule}/orders/execute`,
    payload,
    getHeaders(true, false, false)
  );
}

function getOrderBook() {
  return AXIOS.get(
    `${ordersModule}/info/orderbook`,
    getHeaders(true, false, false)
  );
}

function getFundsData(payload) {
  return AXIOS.get(
    `${fundsModule}/funds/limits`,
    getHeaders(true, false, false)
  );
}
function getDepositDetails() {
  return AXIOS.get(
    `${fundsModule}/payment/details`,
    getHeaders(true, false, false)
  );
}
function cancelPayout(payload) {
  return AXIOS.post(
    `${fundsModule}/payment/payout/cancel`,
    payload,
    getHeaders(true, false, false)
  );
}

function createPayout(payload) {
  return AXIOS.post(
    `${fundsModule}/payment/payout`,
    payload,
    getHeaders(true, false, false)
  );
}

function makePayment(jsonObj) {
  return AXIOS.post(
    `${fundsModule}/payment/create`,
    jsonObj,
    getHeaders(true, false, false)
  );
}

function getComFundsData(payload) {
  return AXIOS.get(
    `${fundsModule}/funds/limits/comm`,
    getHeaders(true, false, false)
  );
}

function cancelOrder(payload) {
  return AXIOS.post(
    `${ordersModule}/orders/cancel`,
    payload,
    getHeaders(true, false, false)
  );
}

function modifyOrder(payload) {
  return AXIOS.post(
    `${ordersModule}/orders/modify`,
    payload,
    getHeaders(true, false, false)
  );
}

function getUserPref() {
  return AXIOS.get(
    `${clientModule}/preferences/web`,
    getHeaders(true, false, false)
  );
}

function updateUserPref(payload) {
  return AXIOS.post(
    `${clientModule}/preferences/web/update`,
    payload,
    getHeaders(true, false, false)
  );
}

function addUserPref(payload) {
  return AXIOS.post(
    `${clientModule}/preferences/add`,
    payload,
    getHeaders(true, false, false)
  );
}

function getTradeBook() {
  return AXIOS.get(
    `${ordersModule}/info/tradebook`,
    getHeaders(true, false, false)
  );
}
function getApiKey() {
  return AXIOS.get("sso/api/key", getHeaders(true, false, false));
}

function generateApiKey(payload) {
  return AXIOS.post(
    "sso/api/key/generate",
    payload,
    getHeaders(true, false, false)
  );
}
function regenerateApiKey(payload) {
  return AXIOS.post(
    "sso/api/key/regenerate",
    payload,
    getHeaders(true, false, false)
  );
}
function getTopSectors(payload) {
  return AXIOS.post(
    `curest/eqsector/scrips`,
    payload,
    getHeaders(true, false, false)
  );
}

function getAlerts() {
  return AXIOS.get(`${alertModule}/alert`, getHeaders(true, false, false));
}

function createAlert(payload) {
  return AXIOS.post(
    `${alertModule}/alert/create`,
    payload,
    getHeaders(true, false, false)
  );
}

function cancelAlert(id) {
  return AXIOS.delete(
    `${alertModule}/alert/delete/${id}`,
    getHeaders(true, false, false)
  );
}

function modifyAlert(payload) {
  return AXIOS.post(
    `${alertModule}/alert/update`,
    payload,
    getHeaders(true, false, false)
  );
}

function sortMwList(payload) {
  return AXIOS.post(
    `${mwModule}/advance/sortMwScrips`,
    payload,
    getHeaders(true, false, false)
  );
}

function getETFDetails(payload) {
  // return AXIOS.post(`curest/etf/getdetails`, payload, getHeaders(true, false, false));
  return AXIOS.get(`${commonModule}/etf`, getHeaders(true, false, false));
}

function getBasketData() {
  // basketorder/report
  return AXIOS.get(
    `${basketModule}/basketorder`,
    getHeaders(true, false, false)
  );
}

function createBasket(payload) {
  return AXIOS.post(
    `${basketModule}/basketorder/create`,
    payload,
    getHeaders(true, false, false)
  );
}

function renameBasket(payload) {
  return AXIOS.post(
    `${basketModule}/basketorder/rename`,
    payload,
    getHeaders(true, false, false)
  );
}

function deleteBasket(id) {
  return AXIOS.delete(
    `${basketModule}/basketorder/delete/${id}`,
    getHeaders(true, false, false)
  );
}

function addScripInBasket(payload) {
  return AXIOS.post(
    `${basketModule}/basketorder/add/scrips`,
    payload,
    getHeaders(true, false, false)
  );
}
function updateScrIpInBasket(payload) {
  return AXIOS.post(
    `${basketModule}/basketorder/update/scrips`,
    payload,
    getHeaders(true, false, false)
  );
}
function refreshBasket(payload) {
  return AXIOS.get(
    `${basketModule}/basketorder/reset/${payload}`,
    getHeaders(true, false, false)
  );
}
function getBasketScrips(basketId) {
  return AXIOS.get(
    `${basketModule}/basketorder/get/scrips/${basketId}`,
    getHeaders(true, false, false)
  );
}

function deleteScripInBasket(payload) {
  return AXIOS.post(
    `${basketModule}/basketorder/delete/scrips`,
    payload,
    getHeaders(true, false, false)
  );
}

function inValidateSession(payload) {
  return AXIOS.post(
    `${clientModule}/profile/invalidateWsSess`,
    payload,
    getHeaders(true, false, false)
  );
}

function createWsSession(payload) {
  return AXIOS.post(
    `${clientModule}/profile/createWsSess`,
    payload,
    getHeaders(true, false, false)
  );
}

function productConvertion(payload) {
  return AXIOS.post(
    `${positionModule}/positions/conversion`,
    payload,
    getHeaders(true, false, false)
  );
}

function getProfileData() {
  return AXIOS.get(
    `${clientModule}/profile/getclientdetails`,
    getHeaders(true, false, false)
  );
}

function getOrderHistory(payload) {
  return AXIOS.post(
    `${ordersModule}/info/history`,
    payload,
    getHeaders(true, false, false)
  );
}

function getTickerTapeList(payload) {
  return AXIOS.post(
    `${mwModule}/ticker-tape/get`,
    payload,
    getHeaders(true, false, false)
  );
}

function getPredefinedMw(payload) {
  return AXIOS.post(
    `${mwModule}/pre-def/mw/get`,
    payload,
    getHeaders(true, false, false)
  );
}

function getSecurityInfo(payload) {
  return AXIOS.post(
    `${scripsModule}/scrip/security/info`,
    payload,
    getHeaders(true, false, false)
  );
}

function getBrokerageDetails(payload) {
  return AXIOS.post(
    `${brokerageModule}/brokerage/calculation`,
    payload,
    getHeaders(true, false, false)
  );
}

function squreOffAll(payload) {
  return AXIOS.post(
    `${ordersModule}/orders/positions/sqroff`,
    payload,
    getHeaders(true, false, false)
  );
}

function getWsSession() {
  return AXIOS.get(
    `${clientModule}/profile/getUser`,
    getHeaders(true, false, false)
  );
}

function getBasketMargin(payload) {
  return AXIOS.post(
    `${basketModule}/basketorder/spanmargin`,
    payload,
    getHeaders(true, false, false)
  );
}

function executeBasket(payload) {
  return AXIOS.post(
    `${basketModule}/basketorder/execute`,
    payload,
    getHeaders(true, false, false)
  );
}
function getBrokerage(payload) {
  return AXIOS_BROKERAGE.post(
    `brokerage/calculateBrokerage`,
    payload,
    getHeaders(true, false, false)
  );
}
function resetBasketExeStatus(basketId) {
  return AXIOS.get(
    `${basketModule}/basketorder/reset/${basketId}`,
    getHeaders(true, false, false)
  );
}

function changePassword(payload) {
  return AXIOS.post(
    `am/access/pwd/change`,
    payload,
    getHeaders(true, false, false)
  );
}

function getPinnedScrips() {
  return AXIOS.get(
    `${clientModule}/startbar/get`,
    getHeaders(true, false, false)
  );
}

function pinToHeader(payload) {
  return AXIOS.post(
    `${clientModule}/startbar/add`,
    payload,
    getHeaders(true, false, false)
  );
}

function getAuthorizeHoldings() {
  return AXIOS.get(
    `${holdingModule}/holdings/nonpoa/cnc`,
    getHeaders(true, false, false)
  );
}

function initAuthorize(payload) {
  return AXIOS.post(
    `${holdingModule}/edis/initialize`,
    payload,
    getHeaders(true, false, false)
  );
}

function submitFeedback(payload) {
  return AXIOS.post(
    `feedback/feedback/sendFeedback`,
    payload,
    getHeaders(true, false, false)
  );
}

function exitBoCoPosition(payload) {
  return AXIOS.post(
    `${ordersModule}/orders/exit/sno`,
    payload,
    getHeaders(true, false, false)
  );
}

function getExchangeStatus() {
  return AXIOS.get(
    `${alertModule}/messages/exch/status`,
    getHeaders(true, false, false)
  );
}

function getExchangeMsg(exchange) {
  return AXIOS.get(
    `${alertModule}/messages/exch?exchange=${exchange}`,
    getHeaders(true, false, false)
  );
}

function getPopContent(payload) {
  return AXIOS.post(
    `notifyrest/notify/get/popup`,
    payload,
    getHeaders(false, false, true)
  );
}

function verifyPayment(jsonObj) {
  return AXIOS.post(
    `${fundsModule}/payment/verify`,
    jsonObj,
    getHeaders(true, false, false)
  );
}
