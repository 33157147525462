<template>
  <div style="height: 100vh">
    <iframe
      id="popoutFrame"
      :src="iframeUrl"
      frameborder="1"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>
<script>
import chartBus from "./chartBus.js";
import commonChart from "./commonChart.js";
export default {
  name: "popOutChart",
  mixins: [commonChart, chartBus],
  data: () => ({
    iframeUrl: "",
  }),

  methods: {
    wsPopOutChart() {
      let tickerData = window.tickers;
      let stream = document.getElementById("popoutFrame");
      if (
        tickerData &&
        stream &&
        stream.contentWindow &&
        stream.contentWindow
      ) {
        let message = {
          id: "stream",
          message: tickerData,
        };

        stream.contentWindow?.postMessage(message, "*");
      }
    },
    async getIframe() {
      let checkStream = document.getElementById("popoutFrame");
      this.$store.commit("chart/setPopOutRefresh", checkStream);
    },
  },
  destroyed() {
    delete window.popout;
    this.$store.commit("chart/setPopOutRefresh", null);
  },
  async mounted() {
    window.popout = {
      callback: this.wsPopOutChart,
    };
    this.iframeUrl = JSON.parse(localStorage.getItem("iframeUrl"));
    await this.getIframe();
  },
  created() {
    this.$store.dispatch("wsConnection/websocketSubscription", {
      data: [],
      where: "popout",
    });
  },
};
</script>
