import { commonService } from "@/services/common.service";
import errorHandling from "@/handleError/errHandling";
const state = {
  fundsData: [],
  handleDeposit: false,
  handleWithdraw: false,
  depositData: [],
  withdrawData: [],
  bankDetails: [],
  fundsLoader: false,
  availableToWithdraw: 0,
  totalBalance: 0,
  withdrawReasonData: [],
  segmentValue: [],
  depositLoader: false,
  withdrawLoader: false,
  checkBalanceLoader: false,
  transactionActiveStatus: false,
  activeTransactionDetails: {},
  segEnableData: [],
  initialSelect: -1,
  formattedBankDetails: [],
};

const actions = {
  async cancelPayout() {
    let json = {
      bankActNo: state.activeTransactionDetails.bankActNo,
      ifscCode: state.activeTransactionDetails.ifscCode,
      segment: state.activeTransactionDetails.segment,
    };
    await commonService
      .cancelPayout(json)
      .then(
        (response) => {
          if (response.status == 200) {
            commit("handleWithdraw", false);
            errorHandling.toaster(
              "",
              "success",
              "Payput cancelled successfully",
              3000,
              "top-right",
              " ",
              " "
            );
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {});
  },
  async createPayout({ commit }, payload) {
    await commonService.createPayout(payload).then(
      (response) => {
        if (response.status == 200 && response.data.status == "Ok") {
          commit("handleWithdraw", false);
          errorHandling.toaster(
            "",
            "success",
            "Payout Success",
            3000,
            "top-right",
            " ",
            " "
          );
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },
  async getDepositDetails({ commit, dispatch }, where) {
    where == "withdraw"
      ? commit("setWithdrawLoader", true)
      : commit("setDepositLoader", true);

    await commonService
      .getDepositDetails()
      .then(
        (response) => {
          if (response.status == 200 && response.data.status == "Ok") {
            where == "withdraw" ? "" : commit("handleDeposit", true);

            var tempArray = [];
            var segmentArray = response.data.result[0].segEnable;
            if (response.data.result && response.data.result.length > 0) {
              segmentArray.forEach((el) => {
                var temp = {};
                if (el == "NSE" || el == "BSE") {
                  temp = {
                    value: "NSE_CASH",
                    showValue: "CASH",
                  };
                } else if (el == "NFO" || el == "BFO") {
                  temp = {
                    value: "NSE_FNO",
                    showValue: "DERIVATIVES",
                  };
                } else if (el == "MCX") {
                  temp = {
                    value: "MCX",
                    showValue: "COMMODITY",
                  };
                } else {
                  //Filler data as validation for other exchanges are not yet finalised
                  temp = {
                    value: "NSE_CASH",
                    showValue: "CASH",
                  };
                }
                tempArray.push(temp);
              });
            }

            const result = tempArray.filter(
              (thing, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.value === thing.value && t.showValue === thing.showValue
                )
            );

            const today = [
              new Date().getFullYear(),
              ("0" + (new Date().getMonth() + 1)).slice(-2),
              ("0" + new Date().getDate()).slice(-2),
            ].join("-");
            const CurrentTime = new Date().toISOString();
            const startTime = new Date(`${today} 19:29:59`).toISOString();
            const endTime = new Date(`${today} 23:59:59`).toISOString();

            let isValidTime =
              new Date(startTime).getTime() < new Date(CurrentTime).getTime() &&
              new Date(CurrentTime).getTime() < new Date(endTime).getTime();
            var intialSelect = isValidTime
              ? result.findIndex((std) => std.value == "MCX")
              : result.findIndex((std) => std.value == "NSE_FNO");
            if (intialSelect == -1) {
              intialSelect = isValidTime
                ? result.findIndex((std) => std.value == "NSE_FNO")
                : result.findIndex((std) => std.value == "NSE_CASH");
            }
            if (intialSelect == -1) {
              intialSelect = isValidTime
                ? result.findIndex((std) => std.value == "NSE_CASH")
                : result.findIndex((std) => std.value == "CD_NSE");
            }
            if (intialSelect == -1) {
              intialSelect = isValidTime
                ? result.findIndex((std) => std.value == "CD_NSE")
                : result.findIndex((std) => std.value == "MCX");
            }
            commit("setSegmentEnable", {
              result: result,
              initialSelect: intialSelect,
            });

            commit(
              "setDepositDetails",
              response.data?.result ? response.data.result : []
            );
            commit(
              "setBankDetails",
              response.data?.result ? response.data.result[0] : {}
            );
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setDepositLoader", false);
      });
  },

  async getFundsData({ state, commit }, payload) {
    commit("setLoader", true);
    await commonService
      .getFundsData()
      .then(
        (response) => {
          if (response.status == 200 && response.data.status == "Ok") {
            commit("setFundsData", response.data.result[0]);
          } else {
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setLoader", false);
      });
  },

  async checkVerifyPayment({ state, commit, dispatch }, payload) {
    await commonService
      .verifyPayment(payload)
      .then(
        (resp) => {
          if (resp.status === 200 && resp.data.status == "Ok") {
            errorHandling.toaster(
              "",
              "success",
              "Payment Successful",
              5000,
              "top-right",
              " ",
              " "
            );
            commit("handleDeposit", false);
          } else {
            errorHandling.toaster(
              resp.data.message,
              "",
              "danger",
              3000,
              "top-right"
            );
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {});
  },
};
const mutations = {
  resetState(state) {
    state.fundsData = [];
    state.handleDeposit = false;
    state.handleWithdraw = false;
    state.depositData = [];
    state.withdrawData = [];
    state.bankDetails = [];
    state.fundsLoader = false;
    state.availableToWithdraw = 0;
    state.totalBalance = 0;
    state.withdrawReasonData = [];
    state.segmentValue = [];
    state.depositLoader = false;
    state.withdrawLoader = false;
    state.checkBalanceLoader = false;
    state.transactionActiveStatus = false;
    state.activeTransactionDetails = {};
    state.segEnableData = [];
    state.initialSelect = -1;
  },
  setSegmentEnable(state, payload) {
    state.segEnableData = payload.result;
    state.initialSelect = payload.initialSelect;
  },
  setActiveTransactionDetails(state, payload) {
    state.activeTransactionDetails = payload;
  },
  setTransactionActiveStatus(state, payload) {
    state.transactionActiveStatus = payload;
  },
  setDepositLoader(state, payload) {
    state.depositLoader = payload;
  },
  setWithdrawLoader(state, payload) {
    state.withdrawLoader = payload;
  },
  setCheckBalance(state, payload) {
    state.availableToWithdraw = payload[8];

    state.totalBalance = payload[9];
  },
  setLoader(state, payload) {
    state.fundsLoader = payload;
  },
  setDepositDetails(state, payload) {
    state.depositData = payload;
  },
  setBankDetails(state, payload) {
    var bankFormat = [];
    state.withdrawReasonData = payload.payoutReasons
      ? payload.payoutReasons
      : [];

    for (let item of payload?.bankDetails) {
      item.showValue = `${item.bankName} - ${item.bankActNo}`;
      item.accNo = item.bankActNo;
      item.holderName = payload.userName;
      item.bankShotName = item.bankCode;
      bankFormat.push(item.showValue);
    }

    state.account = temp;
    state.segmentValue = payload.segEnable ? payload.segEnable : [];
    if (state.fundsPreference?.fundsOpt == "deposite") {
      var tempArray = [];
      for (let item of payload.segEnable) {
        var temp;
        item == "NSE"
          ? (temp = {
              value: "NSE_CASH",
              showValue: "CASH",
            })
          : item == "BSE"
          ? (temp = {
              value: "BSE_CASH",
              showValue: "CASH",
            })
          : item == "NFO"
          ? (temp = {
              value: "NSE_FNO",
              showValue: "DERIVATIVES",
            })
          : item == "BFO"
          ? (temp = {
              value: "BSE_FNO",
              showValue: "DERIVATIVES",
            })
          : item == "MCX"
          ? (temp = {
              value: "MCX",
              showValue: "COMMODITY",
            })
          : item == "CDS"
          ? (temp = {
              value: "CD_NSE",
              showValue: "CURRENCY",
            })
          : item == "BCD"
          ? (temp = {
              value: "CD_BSE",
              showValue: "CURRENCY",
            })
          : "";
        tempArray.push(temp);
      }
      const result = tempArray.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) => t.value === thing.value && t.showValue === thing.showValue
          )
      );
      const today = [
        new Date().getFullYear(),
        ("0" + (new Date().getMonth() + 1)).slice(-2),
        ("0" + new Date().getDate()).slice(-2),
      ].join("-");
      const CurrentTime = new Date().toISOString();
      const startTime = new Date(`${today} 19:29:59`).toISOString();
      const endTime = new Date(`${today} 23:59:59`).toISOString();
      let isValidTime =
        new Date(startTime).getTime() < new Date(CurrentTime).getTime() &&
        new Date(CurrentTime).getTime() < new Date(endTime).getTime();

      var intialSelect = -1;
      //  valid Time
      if (isValidTime) {
        intialSelect = result.findIndex((std) => std.value == "MCX");
        if (intialSelect == -1) {
          intialSelect = result.findIndex((std) => std.value == "NSE_FNO");
        }
        if (intialSelect == -1) {
          intialSelect = result.findIndex((std) => std.value == "NSE_CASH");
        }
        if (intialSelect == -1) {
          intialSelect = result.findIndex((std) => std.value == "CD_NSE");
        }
      }

      //  normal Time
      if (intialSelect == -1) {
        intialSelect = result.findIndex((std) => std.value == "NSE_FNO");
      }
      if (intialSelect == -1) {
        intialSelect = result.findIndex((std) => std.value == "NSE_CASH");
      }
      if (intialSelect == -1) {
        intialSelect = result.findIndex((std) => std.value == "CD_NSE");
      }
      if (intialSelect == -1) {
        intialSelect = result.findIndex((std) => std.value == "MCX");
      }

      // for else
      if (intialSelect == -1) {
        intialSelect = result.findIndex((std) => std.value == "BSE_FNO");
      }
      if (intialSelect == -1) {
        intialSelect = result.findIndex((std) => std.value == "BSE_CASH");
      }
      if (intialSelect == -1) {
        intialSelect = result.findIndex((std) => std.value == "CD_BSE");
      }

      payload.intialSelect =
        intialSelect > -1 && result[intialSelect].value
          ? result[intialSelect].value
          : "";
      payload.segEna =
        intialSelect > -1 && result[intialSelect].value
          ? [result[intialSelect]]
          : result;
    } else {
      var payOutSeg = [];
      for (let item of payload.segEnable) {
        var temp;
        item == "NSE" || item == "BSE"
          ? (temp = {
              value: "NSE_CASH",
              showValue: "CASH",
            })
          : item == "NFO" || item == "BFO"
          ? (temp = {
              value: "NSE_FNO",
              showValue: "DERIVATIVES",
            })
          : item == "MCX"
          ? (temp = {
              value: "MCX",
              showValue: "COMMODITY",
            })
          : item == "CDS" || item == "BCD"
          ? (temp = {
              value: "CD_NSE",
              showValue: "CURRENCY",
            })
          : "";
        payOutSeg.push(temp);
      }

      const payOutUniqueArr = payOutSeg.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) => t.value === thing.value && t.showValue === thing.showValue
          )
      );

      payload.segEna = payOutUniqueArr;
    }

    payload.prevupiid = payload.upiId;
    payload.upiId == "NA"
      ? (payload.upiId = "")
      : (payload.upiId = payload.upiId);

    state.bankDetails = payload;

    state.formattedBankDetails = bankFormat;
  },
  setWithdrawDetails(state, payload) {
    state.withdrawData = payload;
  },
  handleDeposit(state, payload) {
    state.handleDeposit = payload;
  },
  handleWithdraw(state, payload) {
    state.handleWithdraw = payload;
  },
  setFundsData(state, payload) {
    state.fundsData = payload;
  },
};
const getters = {};

const funds = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters,
};
export default funds;
