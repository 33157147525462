import { commonService } from "../../services/common.service";
import errorHandling from "../../handleError/errHandling";

const state = {
  alertsList: "",
  alertsLoader: false,
  isAlertDialog: false,
  alertType: "Create",
  modifyData: "",
  alertData: "",
  ltpValue: "",
  exchangeStatus: [],
  nseStatus: "NA",
  bseStatus: "NA",
  mcxStatus: "NA",
  marketStatus: [],
  exchangeMsg: [],
  nseMsg: [],
  bseMsg: [],
  mcxMsg: [],
  cdsMsg: [],
  searchListForAlert: [],
  cancelAlertDialog: false,
  marketTabs: [
    { name: "NSE", id: 0, active: true },
    { name: "BSE", id: 1, active: false },
    { name: "MCX", id: 2, active: false },
  ],
  exchStatusLoader: false,
  exchMsgLoader: false,
};
const actions = {
  async getAlerts({ state, commit, dispatch, rootGetters, rootState }) {
    commit("setLoader", true);
    let json = {
      userId: rootGetters["login/getUCC"],
    };
    await commonService
      .getAlerts(json)
      .then(
        (resp) => {
          if (resp.data.status == "Ok" && resp.data.message == "Success") {
            dispatch("setAlerts", resp.data.result);
            let arr = rootState.orderTabList;
            arr.forEach((el) => {
              if (el.name == "Alerts") {
                el.value = el.name;
                el.value =
                  resp.data.result?.length > 0
                    ? el.value + " " + `(${resp.data?.result.length})`
                    : el.value;
              }
            });
            state.isAlertDialog = false;
            commit("setLoader", false);
          } else {
            dispatch("setAlerts", []);
            let arr = rootState.orderTabList;
            arr.forEach((el) => {
              if (el.name == "Alerts") {
                el.value = el.name;
              }
            });
          }
        },
        (error) => {
          errorHandling.errLog(error);
        }
      )

      .finally(() => {
        commit("setLoader", false);
        commit("setCancelAlertDialog", false);
      });
  },

  async createAlert({ state, commit, dispatch, rootGetters }, payload) {
    await commonService.createAlert(payload).then(
      (resp) => {
        if (resp.data.status == "Ok" && resp.data.message == "Success") {
          dispatch("getAlerts");
          errorHandling.toaster(
            "",
            "success",
            'Alert created successfully',
            4500,
            "",
            " ",
            " "
          );
        } else {
        }
      },
      (error) => {
        errorHandling.errLog(error);
      }
    );
  },

  async cancelAlert({ state, dispatch, commit, rootGetters }, payload) {
    await commonService
      .cancelAlert(payload)
      .then(
        (resp) => {
          if (resp.data.status == "Ok" && resp.data.message == "Success") {
            commit("setCancelAlertDialog", false);
            dispatch("getAlerts");
            errorHandling.toaster(
              "",
              "success",
              'Alert cancelled successfully',
              4500,
              "",
              " ",
              " "
            );
          } else {
          }
        },
        (error) => {
          errorHandling.errLog(error);
        }
      )

      .finally(() => {});
  },

  async modifyAlert({ state, dispatch, commit, rootGetters }, payload) {
    await commonService
      .modifyAlert(payload)
      .then(
        (resp) => {
          if (resp.data.status == "Ok" && resp.data.message == "Success") {
            dispatch("getAlerts");
            errorHandling.toaster(
              "",
              "success",
              'Alert modified successfully',
              4500,
              "",
              " ",
              " "
            );
          } else {
          }
        },
        (error) => {
          errorHandling.errLog(error);
        }
      )

      .finally(() => {});
  },

  async setAlerts({ state, dispatch, rootGetters }, payload) {
    state.alertsList = payload;
    dispatch(
      "order/setOrdersTabsCount",
      {
        open: rootGetters["order/getOpenOrders"],
        exec: rootGetters["order/getExcutedOrders"],
        alerts: state.alertsList,
        tradeBook: rootGetters["order/getTradeBook"],
        positions: rootGetters["positions/getPositionsData"],
      },
      { root: true }
    );
  },

  async getExchangeStatus({ state, commit, rootGetters }, payload) {
    commit("setExchStatusLoader", true);
    await commonService
      .getExchangeStatus(payload)
      .then(
        (response) => {
          if (
            response.data.status == "Ok" &&
            response.data.message == "Success" &&
            response.data.result &&
            response.data.result.length > 0
          ) {
            commit("setExchangeStatus", response.data.result);
          } else {
            commit("setExchangeStatus", []);
          }
        },
        (error) => {
          errorHandling.errLog(error);
        }
      )

      .finally(() => {
        commit("setExchStatusLoader", false);
      });
  },

  async getExchangeMsg({ state, commit, rootGetters }, payload) {
    commit("setExchMsgLoader", true);
    await commonService
      .getExchangeMsg(payload)
      .then(
        (response) => {
          if (
            response.data.status == "Ok" &&
            response.data.message == "Success"
          ) {
            state.cdsMsg = [];
            state.nseMsg = [];
            state.mcxMsg = [];
            state.bseMsg = [];
            commit("setExchangeMsg", response.data.result);
          } else {
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setExchMsgLoader", false);
      });
  },
};

const mutations = {
  resetState(state) {
    state.alertsList = "";
    state.marketStatus = [];
    state.alertsLoader = false;
    state.isAlertDialog = false;
    state.alertType = "Create";
    state.modifyData = "";
    state.alertData = "";
    state.ltpValue = "";
    state.exchangeStatus = [];
    state.nseStatus = "NA";
    state.bseStatus = "NA";
    state.mcxStatus = "NA";
    state.exchangeMsg = [];
    state.nseMsg = [];
    state.bseMsg = [];
    state.mcxMsg = [];
    state.cdsMsg = [];
    state.searchListForAlert = [];
    state.cancelAlertDialog = false;
    state.marketTabs = [
      { name: "NSE", id: 0, active: true },
      { name: "BSE", id: 1, active: false },
      { name: "MCX", id: 2, active: false },
    ];
    state.exchStatusLoader = false;
    state.exchMsgLoader = false;
  },
  setLoader(state, payload) {
    state.alertsLoader = payload;
  },
  setCancelAlertDialog(state, payload) {
    state.cancelAlertDialog = payload;
  },
  setSearchListForAlert(state, payload) {
    state.searchListForAlert = payload;
  },
  setIsAlertDialog(state, payload) {
    state.isAlertDialog = payload.boolean;
    state.alertType = payload?.type;
    state.alertData = payload?.data;
  },
  setCurrentAlertData(state, payload) {
    state.modifyData = payload;
  },
  setLtpValue(state, payload) {
    state.ltpValue = payload;
  },
  setExchangeStatus(state, payload) {
    state.exchangeStatus = payload;
    let data = [];
    if (payload.length > 0) {
      payload.forEach((element) => {
        if (
          element.type == "NORMAL" ||
          element.type.toLowerCase() == "normal market"
        ) {
          data.push(element);
        }
      });
    }

    state.marketStatus = data;
  },

  setExchangeMsg(state, payload) {
    state.exchangeMsg = payload;
    payload.forEach((element) => {
      if (element.exchange == "NSE") {
        state.nseMsg.push(element.exchangemsg);
      }
      if (element.exchange == "CDS") {
        state.cdsMsg.push(element.exchangemsg);
      }
      if (element.exchange == "BSE") {
        state.bseMsg.push(element.exchangemsg);
      }
      if (element.exchange == "MCX") {
        state.mcxMsg.push(element.exchangemsg);
      }
    });
  },
  setMarketTabs(state, payload) {
    state.marketTabs = payload;
  },
  setExchStatusLoader(state, payload) {
    state.exchStatusLoader = payload;
  },
  setExchMsgLoader(state, payload) {
    state.exchMsgLoader = payload;
  },
};

const getters = {
  getAlerts: (state) => state.alertsList,
  getIsAlertDialog: (state) => state.isAlertDialog,
  getAlertType: (state) => state.alertType,
  getModifyData: (state) => state.modifyData,
  getAlertCurrentData: (state) => state.alertData,
  getLtpValue: (state) => state.ltpValue,
  getExchangeStatus: (state) => state.exchangeStatus,
  getExchangeMsg: (state) => state.exchangeMsg,
  getMarketTabs: (state) => state.marketTabs,
  getExchStatusLoader: (state) => state.exchStatusLoader,
  getExchMsgLoader: (state) => state.exchMsgLoader,
};

const alert = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default alert;
