import errorHandling from "@/handleError/errHandling";
import { commonService } from "@/services/common.service";

const state = {
  gttWindow: false,
  gttSearchList: [],
  createGtt: false,
  gttData: "",
  gttList: [],
  gtt52WH: "0.00",
  gttAction: "",
  gttProperty: "Greater than (>)",
  gttValType: "LTP",

  gttOrderNo: "",
  gttSearchTable: "",
  searchLoader: false,
  gttColor: true,
};

const mutations = {
  resetState(state, payload) {
    state.gttWindow = false;
    state.gttSearchList = [];
    state.createGtt = false;
    state.gttColor = true;
    state.gttData = "";
    state.gttList = [];
    state.gtt52WH = "0.00";
    state.gttAction = "";
    state.gttProperty = "Greater than (>)";
    state.gttValType = "LTP";

    state.gttOrderNo = "";
    state.gttSearchTable = "";
    state.searchLoader = false;
  },
  setGttColor(state, payload) {
    state.gttColor = payload;
  },
  setSearchListForGtt(state, payload) {
    state.gttSearchList = payload;
  },
  setGttWindow(state, payload) {
    state.gttWindow = payload;
  },
  setGttStatus(state, payload) {
    state.createGtt = payload;
  },
  setGttData(state, payload) {
    state.gttData = payload;
  },
  setGttList(state, payload) {
    state.gttList = payload;
  },

  set52WHPrice(state, payload) {
    state.gtt52WH = payload;
  },
  setAction(state, payload) {
    state.gttAction = payload;
  },
  setProperty(state, payload) {
    state.gttProperty = payload;
  },
  setType(state, payload) {
    state.gttValType = payload;
  },
  setOrderNo(state, payload) {
    state.gttOrderNo = payload;
  },
  setGttSearch(state, payload) {
    state.gttSearchTable = payload;
  },
  setSearchLoader(state, payload) {
    state.searchLoader = payload;
  },
};

const actions = {
  async getGttOrderBook({ commit, rootState }) {
    await commonService.getGttOrderBook().then(
      (res) => {
        if (
          res.status == 200 &&
          res.data.status == "Ok" &&
          res.data.message == "Success" &&
          res.data.result &&
          res.data.result.length > 0
        ) {
          commit("setGttList", res.data.result);
          let arr = rootState.orderTabList;
          arr.forEach((el) => {
            if (el.name == "GTT") {
              el.value = el.name;
              el.value =
                res.data.result?.length > 0
                  ? el.value + " " + `(${res.data?.result.length})`
                  : el.value;
            }
          });
        } else {
          commit("setGttList", []);
          let arr = rootState.orderTabList;
          arr.forEach((el) => {
            if (el.name == "GTT") {
              el.value = el.name;
            }
          });
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },
  async executeGtt({ commit, dispatch }, payload) {
    await commonService.executeGtt(payload).then(
      (res) => {
        if (
          res.status == 200 &&
          res.data.status == "Ok" &&
          res.data.message == "Success"
        ) {
          dispatch("getGttOrderBook");
          errorHandling.toaster(
            "",
            "success",
            "GTT Order Placed",
            3000,
            "top-right",
            " ",
            " "
          );
        } else {
          errorHandling.toaster(
            "",
            "danger",
            res.data?.message ? res.data.message : "Failed",
            3000,
            "top-right",
            " ",
            " "
          );
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },
  async modifyGtt({ commit, dispatch }, payload) {
    await commonService.modifyGtt(payload).then(
      (res) => {
        if (
          res.status == 200 &&
          res.data.status == "Ok" &&
          res.data.message == "Success"
        ) {
          dispatch("getGttOrderBook");
          errorHandling.toaster(
            "",
            "success",
            "GTT Order Modified",
            3000,
            "top-right",
            " ",
            " "
          );
        } else {
          errorHandling.toaster(
            "",
            "danger",
            response.data?.message ? response.data.message : "Failed",
            3000,
            "top-right",
            " ",
            " "
          );
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },
  async deleteGtt({ dispatch }, payload) {
    await commonService.deleteGtt(payload).then(
      (res) => {
        if (
          res.status == 200 &&
          res.data.status == "Ok" &&
          res.data.message == "Success"
        ) {
          dispatch("getGttOrderBook");
          errorHandling.toaster(
            "",
            "success",
            "GTT Order Cancelled",
            3000,
            "top-right",
            " ",
            " "
          );
        } else {
          errorHandling.toaster(
            "",
            "danger",
            response.data?.message ? response.data.message : "Failed",
            3000,
            "top-right",
            " ",
            " "
          );
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },
  async closeGtt({ commit, dispatch }) {
    dispatch("orderWindow/unsubScribe", "", { root: true });
    commit("setGttWindow", false);
    commit("setGttStatus", false);
    commit("setGttColor", true);
    commit("setType", "LTP");
    dispatch("wsConnection/depthSubscription", "", { root: true });
  },
};

const getters = {};

const gtt = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default gtt;
