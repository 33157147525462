<template></template>
<script>
import commonFunctions from "@/mixins/commonFunctions";
export default {
  name: "depth",
  data: () => ({
    depthIndex: -1,
  }),

  mixins: [commonFunctions],
  props: {
    item: Object,
    index: Number,
    watchData: Array,
  },
  methods: {
    setTofixed(val, index) {
      let exchange = this.watchData;
      return exchange == "CDS" || exchange == "BCD"
        ? parseFloat(val).toFixed(4)
        : parseFloat(val).toFixed(2);
    },
  },
};
</script>
