import Vue from "vue";
import VueRouter from "vue-router";
import _store from "../store";
import chart from "../views/chart/chart.vue";
import popoutChart from "../views/chart/popoutChart.vue";
import marketwatch from "../views/marketwatch/marketwatch.vue";
import errorHandling from "@/handleError/errHandling";
import notiAlerts from "../views/notiAlerts/notiAlerts.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/index.vue"),
  },
  {
    path: "/layout",
    name: "layout",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/main.vue"),
    //redirect: "/home",
    children: [
      {
        path: "/marketwatch",
        name: "marketwatch",
        component: marketwatch,
      },
      {
        path: "/alerts",
        name: "alerts",
        component: notiAlerts,
      },
      {
        path: "/popout",
        name: "popout",
        component: popoutChart,
      },
      {
        path: "/chart",
        name: "chart",
        component: chart,
      },
      {
        path: "/orders",
        name: "orders",
        component: () =>
          import(/* webpackChunkName: "orders" */ "../views/orders/orders.vue"),
      },
      {
        path: "/positions",
        name: "positions",
        component: () =>
          import(
            /* webpackChunkName: "positions" */ "../views/positions/positions.vue"
          ),
      },
      {
        path: "/holdings",
        component: () =>
          import(
            /* webpackChunkName: "holdings" */ "../views/holdings/holdings.vue"
          ),
      },
      {
        path: "/funds",
        component: () =>
          import(/* webpackChunkName: "funds" */ "../views/funds/funds.vue"),
      },
      {
        path: "/settings",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/settings.vue"
          ),
      },
      {
        path: "/apps",
        component: () =>
          import(/* webpackChunkName: "apps" */ "../views/apps/apps.vue"),
      },
      {
        path: "/home",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/home/home.vue"),
      },
      {
        path: "/exchange",
        name: "exchange",
        component: () => import("../views/home/exchange.vue"),
      },
      {
        path: "/optionchain",
        name: "optionchain",
        component: () => import("../views/optionChain/optionchain.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
router.beforeEach(async (to, from, next) => {
  let theme = localStorage.getItem("chartTheme") && localStorage.getItem("chartTheme") != "undefined" ? JSON.parse(localStorage.getItem("chartTheme")) : "0"
  if(theme){
    _store.commit('setthmValue', theme)
  }
  let recentSeach = JSON.parse(localStorage.getItem("recentSeach"));
  if (recentSeach) {
    let json = {
      isUpdate : true,
      data : recentSeach
    }
    _store.commit("marketWatch/setRecentSearchData", json);
  }
  let userStage = JSON.parse(localStorage.getItem("stage"));
  if (userStage) {
    _store.commit("login/setStage", userStage);
  }
  _store.state.login.password = "";

  let quries =
    localStorage.getItem("gwTabQueries") &&
    localStorage.getItem("gwTabQueries") != undefined
      ? JSON.parse(localStorage.getItem("gwTabQueries"))
      : _store.state.defaultQueries;
  _store.commit("setQueries", { data: quries, action: "intial" });

  let isSticky = localStorage.getItem("isSticky")
    ? JSON.parse(localStorage.getItem("isSticky"))
    : false;
  _store.commit("setStickyOrder", isSticky);
  _store.commit("login/setSsoQuery", "");

  if (
    Object.keys(to.query).length > 0 &&
    to.query.constructor === Object &&
    to.path == "/"
  ) {
    validSession();
    _store.commit("login/setSsoQuery", to.query);
    _store.dispatch("login/validateVendor", { action: "initial" });
    next();
  } else {
    if (validSession() && to.path == "/") {
      _store.dispatch("login/getUserprofile");
      next();
    } else if (!validSession() && from.path == "/" && to.path != "/") {
      next("/");
    } else if (
      !validSession() &&
      from.path == "/" &&
      to.path == "/" &&
      userStage &&
      (userStage !== "password" || userStage !== "user")
    ) {
      errorHandling.resetLocal(false);
      next();
    } else {
      next();
    }
  }
});

function validSession() {
  let userId = JSON.parse(localStorage.getItem("UCC"));
  let isExist = JSON.parse(localStorage.getItem("isExistUser"));

  isExist ? _store.commit("login/setIsExistUser", isExist) : "";
  let accessToken = JSON.parse(localStorage.getItem("accessToken"));
  var chartIndicators = JSON.parse(localStorage.getItem("chartIndicators"));
  if (chartIndicators) {
    _store.commit("chart/setChartIndicators", chartIndicators);
  }
  if (userId) {
    _store.commit("login/setUCC", userId);
    _store.commit("login/setCurrentUserId", userId);
  }
  if (accessToken) {
    _store.commit("login/setLoginData", accessToken);
  }
  let valid;
  userId && accessToken ? (valid = true) : (valid = false);

  return valid;
}
