import router from "@/router";

const header = {
  namespaced: true,

  state: {
    niftyFiftyArray: [],
    niftyFinServiceArray: [],
    niftyBankArray: [],
    indiaVixArray: [],
    senSexArray: [],
    logoutLoader: false,
    menuList: [],
    tabSelection: 0,
    headerArray: [
      {
        stockName: "NIFTYBANK",
        symbol: "NIFTY BANK",
        tradingSymbol: "NIFTY BANK",
        formattedInsName: "NIFTY BANK",
        exchange: "NSE",
        exchIndex: "NSE::index",
        segment: "nse_idx",
        token: "26009",
        expiry: null,
        pdc: null,
        ltp: "0.00",
        pc: "0.00",
        chg: "0.00",
        source: "WEB",
      },
      {
        stockName: "SENSEX",
        ltp: "0.00",
        pc: "0.00",
        chg: "0.00",
        symbol: "SENSEX",
        tradingSymbol: "SENSEX",
        formattedInsName: "SENSEX",
        exchange: "BSE",
        segment: "bse_idx",
        token: "1",
        expiry: null,
        pdc: "19122.15",

        source: "WEB",
        exchIndex: "BSE::index",
      },
      {
        stockName: "NIFTY50",
        ltp: "0.00",
        pc: "0.00",
        chg: "0.00",
        symbol: "NIFTY 50",
        tradingSymbol: null,
        formattedInsName: "NIFTY 50",
        exchange: "NSE",
        segment: "nse_idx",
        token: "26000",
        expiry: null,
        pdc: "19122.15",

        source: "WEB",
        exchIndex: "NSE::index",
      },
      {
        stockName: "INDIAVIX",
        ltp: "0.00",
        pc: "0.00",
        chg: "0.00",

        symbol: "INDIA VIX",
        tradingSymbol: "INDIA VIX",
        formattedInsName: "INDIA VIX",
        exchange: "NSE",
        segment: "nse_idx",
        token: "26017",
        expiry: null,
        pdc: "19122.15",

        source: "WEB",
        exchIndex: "NSE::index",
      },
      {
        stockName: "NIFTYFINSERVE",
        ltp: "0.00",
        pc: "0.00",
        chg: "0.00",

        exchange: "NSE",

        symbol: "NIFTY FIN SERVICE",
        tradingSymbol: "NIFTY FIN SERVICE",
        formattedInsName: "NIFTY FIN SERVICE",

        segment: "nse_idx",
        token: "26037",
        expiry: null,
        pdc: "19122.15",

        source: "WEB",
        exchIndex: "NSE::index",
      },
    ],
  },
  mutations: {
    resetState(state, payload) {
      state.logoutLoader = false;
      state.tabSelection = 0;
      state.niftyFiftyArray =
        state.niftyFinServiceArray =
        state.niftyBankArray =
        state.indiaVixArray =
        state.senSexArray =
        state.menuList =
          [];
    },
    setNseIndex(state, payload) {
      for (let item of payload) {
        var tempChange =
          (item.IndexChange / (item.IndexValue + item.IndexChange)) * 100;

        var tempData = {
          name: item.IndexName,
          value: item.IndexValue,
          change: tempChange.toString() == "NaN" ? "0.00" : tempChange,
          token:
            item.IndexName == "NIFTY FIN SERVICE"
              ? "26037"
              : item.IndexName == "Nifty Bank"
              ? "26009"
              : item.IndexName == "Nifty 50"
              ? "26000"
              : item.IndexName == "India VIX"
              ? "26017"
              : "",
          chart: "::index",
          ex: "NSE",
          o: item.IndexValue,
          h: item.IndexValue,
          l: item.IndexValue,
        };
        tempData.websocketSub = tempData.ex + "|" + tempData.token;
        tempData.chartExchange = tempData.exch;
        tempData.chartToken = tempData.token;
        tempData.chartSymbol =
          tempData.name + "::" + tempData.ex.toLocaleLowerCase() + "::INDEX";
        if (item.IndexName == "Nifty Bank") {
          state.niftyBankArray = tempData;
        }
        if (item.IndexName == "Nifty 50") {
          state.niftyFiftyArray = tempData;
        }
        if (item.IndexName == "NIFTY FIN SERVICE") {
          state.niftyFinServiceArray = tempData;
        }
        if (item.IndexName == "India VIX") {
          state.indiaVixArray = tempData;
        }
      }
    },
    setBseIndex(state, payload) {
      for (let item of payload) {
        var tempChange =
          (item.IndexChange / (item.IndexValue + item.IndexChange)) * 100;
        var tempData = {
          name: item.IndexName,
          value: item.IndexValue,
          change: tempChange.toString() == "NaN" ? "0.00" : tempChange,
          token: item.IndexName == "SENSEX" ? "1" : "",
          chart: "::index",
          ex: "BSE",
          o: item.IndexValue,
          h: item.IndexValue,
          l: item.IndexValue,
        };
        tempData.websocketSub = tempData.ex + "|" + tempData.token;
        tempData.chartExchange = tempData.exch;
        tempData.chartToken = tempData.token;
        tempData.chartSymbol =
          tempData.name + "::" + tempData.ex.toLocaleLowerCase() + "::INDEX";
        if (item.IndexName == "SENSEX") {
          state.senSexArray = tempData;
        }
      }
    },
  },
  actions: {
    logout({ state, dispatch }) {
      state.logoutLoader = true;
    },
    async getIndex({ commit, rootGetters }, payload) {
      let jsonObj = {
        //userId: rootGetters["authentication/getUserId"],
        exch: payload,
      };
    },
    setIndexSocketValues({ state, commit }, payload) {
      if (payload.tk == "1") {
        state.senSexArray["value"] = !!payload.lp
          ? payload.lp
          : state.senSexArray["value"];
        state.senSexArray["change"] = !!payload.pc
          ? payload.pc
          : state.senSexArray["change"];
        state.senSexArray["o"] = !!payload.o
          ? payload.o
          : state.senSexArray["o"];
        state.senSexArray["h"] = !!payload.h
          ? payload.h
          : state.senSexArray["h"];
        state.senSexArray["l"] = !!payload.l
          ? payload.l
          : state.senSexArray["l"];
      }
      if (payload.tk == "26017") {
        state.indiaVixArray["value"] = !!payload.lp
          ? payload.lp
          : state.indiaVixArray["value"];
        state.indiaVixArray["change"] = !!payload.pc
          ? payload.pc
          : state.indiaVixArray["change"];
        state.indiaVixArray["o"] = !!payload.o
          ? payload.o
          : state.indiaVixArray["o"];
        state.indiaVixArray["h"] = !!payload.h
          ? payload.h
          : state.indiaVixArray["h"];
        state.indiaVixArray["l"] = !!payload.l
          ? payload.l
          : state.indiaVixArray["l"];
      }
      if (payload.tk == "26009") {
        state.niftyBankArray["value"] = !!payload.lp
          ? payload.lp
          : state.niftyBankArray["value"];
        state.niftyBankArray["change"] = !!payload.pc
          ? payload.pc
          : state.niftyBankArray["change"];
        state.niftyBankArray["o"] = !!payload.o
          ? payload.o
          : state.niftyBankArray["o"];
        state.niftyBankArray["h"] = !!payload.h
          ? payload.h
          : state.niftyBankArray["h"];
        state.niftyBankArray["l"] = !!payload.l
          ? payload.l
          : state.niftyBankArray["l"];
      }
      if (payload.tk == "26000") {
        state.niftyFiftyArray["value"] = !!payload.lp
          ? payload.lp
          : state.niftyFiftyArray["value"];
        state.niftyFiftyArray["change"] = !!payload.pc
          ? payload.pc
          : state.niftyFiftyArray["change"];
        state.niftyFiftyArray["o"] = !!payload.o
          ? payload.o
          : state.niftyFiftyArray["o"];
        state.niftyFiftyArray["h"] = !!payload.h
          ? payload.h
          : state.niftyFiftyArray["h"];
        state.niftyFiftyArray["l"] = !!payload.l
          ? payload.l
          : state.niftyFiftyArray["l"];
      }
      if (payload.tk == "26037") {
        state.niftyFinServiceArray["value"] = !!payload.lp
          ? payload.lp
          : state.niftyFinServiceArray["value"];
        state.niftyFinServiceArray["change"] = !!payload.pc
          ? payload.pc
          : state.niftyFinServiceArray["change"];
        state.niftyFinServiceArray["o"] = !!payload.o
          ? payload.o
          : state.niftyFinServiceArray["o"];
        state.niftyFinServiceArray["h"] = !!payload.h
          ? payload.h
          : state.niftyFinServiceArray["h"];
        state.niftyFinServiceArray["l"] = !!payload.l
          ? payload.l
          : state.niftyFinServiceArray["l"];
      }
    },
    setTabSelection({ state, commit, dispatch }, payload) {
      state.tabSelection = payload;
    },
    setMenuList({ state, commit, dispatch }, payload) {
      var lapViewData = [
        { text: "home", svg: "home" },
        { text: "orders", svg: "Orders" },
        { text: "positions", svg: "Positions" },
        { text: "holdings", svg: "Holdings" },
        { text: "funds", svg: "Funds" },
        { text: "apps", svg: "AppsIcon" },
        { text: "settings", svg: "Settings" },
      ];
      var mobileView = [
        { text: "home", svg: "home" },
        { text: "marketwatch", svg: "marketwatch" },
        { text: "orders", svg: "Orders" },
        { text: "positions", svg: "Positions" },
        { text: "holdings", svg: "Holdings" },
        { text: "funds", svg: "Funds" },
        { text: "apps", svg: "AppsIcon" },
        { text: "settings", svg: "Settings" },
      ];
      payload ? (state.menuList = mobileView) : (state.menuList = lapViewData);
    },
    getMenuList({ state, commit, dispatch }, payload) {
      var index = state.menuList.findIndex(
        (std) => std.text === router.currentRoute.path.replace("/", "")
      );
      dispatch("setTabSelection", index);
    },
  },
  getters: {},
};

export default header;
