<template>
  <div id="notialerts_container">
    <div
      class="sticky z5"
      :class="$vuetify.theme.dark ? 'lighttoolTipColor' : 'bg-F9F9FB'"
    >
      <div class="row ma-0 justify-space-between">
        <div class="py-2" id="holdings_button_list">
          <tab-menu
            :buttonsList="notiAlertTabList"
            :tabName="'Notifications'"
          ></tab-menu>
        </div>
        <span
          v-if="
            searchNotifification == '' ? notificationList : notiSearchFilter()
          "
          class="d-flex py-3 px-3 align-middle"
        >
          <v-sheet v-click-outside="resetSearch" class="search-box mx-2">
            <span id="noti_search_input_container" class="searchwhole">
              <customIcon
                :name="'search'"
                class="searchimg"
                :width="'16'"
                :height="'16'"
                :color="$store.state.amogaColor"
              />
              <span class="searchinput">
                <input
                  id="notisearch"
                  class="placeholdsearch"
                  placeholder="Search"
                  autocorrect="off"
                  v-model="searchNotifification"
                  oninput="this.value = this.value.toUpperCase()"
                />
              </span>
            </span>
          </v-sheet>

          <!--   <v-tooltip
            top
            :color="
              $vuetify.theme.dark
                ? 'darktoolTipColor toolTipTop darkTooltipTop'
                : 'lighttoolTipColor toolTipTop lightTooltipTop'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <a id="hold">
                <span
                  v-bind="attrs"
                  v-on="on"
                
                  @click="
                    checkDownload('notifications', 'notifications', notificationList)
                  "
                >
                  <customIcon
                    :name="'download'"
                    class="mx-1 cursor d-flex"
                    :width="'21'"
                    :height="'21'"
                    :color="$store.state.amogaColor"
                  />
                </span>
              </a>
            </template>
            <span>Download</span>
          </v-tooltip> -->
        </span>
      </div>
      <hr
        role="separator"
        aria-orientation="horizontal"
        class="mx-4 my-1 v-divider"
        :class="$vuetify.theme.dark ? '' : 'theme--light'"
      />
    </div>
    <div>
      <div>
        <v-container
          v-if="
            searchNotifification == ''
              ? notificationList.length > 0
              : notiSearchFilter().length > 0
          "
        >
          <v-expansion-panels multiple accordion v-model="activeIndex">
            <v-expansion-panel
              v-for="(item, index) in searchNotifification == ''
                ? notificationList
                : notiSearchFilter()"
              :key="index"
            >
              <v-expansion-panel-header>
                {{ item.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  :class="
                    item.messageType == 'ResearchCall' ? 'notiPointer' : ''
                  "
                  @click="handleMessageClick(item.messageType)"
                >
                  {{ item.message }}
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>

        <div v-else>
          <noData id="Nodata_niti_component" noDataValue="Records" />
        </div>
      </div>
    </div>

    <div></div>
    <bottomSheet
      id="bottomsheet_holdings_component"
      v-if="$store.state.isMobileView"
      :currentData="currentSheetData"
      v-on:from-child="getChildData"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import noData from "../../components/noData.vue";
export default {
  name: "notiAlerts",
  data: () => ({
    value: "",
    searchNotifification: "",
    activeIndex: [],
  }),
  computed: {
    ...mapState(["notificationList", "notiAlertTabList"]),
  },
  watch: {
    activeIndex: function (newVal, oldVal) {},
  },
  components: { noData },

  methods: {
    resetSearch() {},
    async handleMessageClick(type) {
      if (type == "ResearchCall") {
        await this.$router.push("/orders").finally(() => {
          this.$store.commit("setActivePageTab", "Basket");
          this.$store.commit("setQueries", {
            data: { tab: "Basket" },
            action: "change",
          });
        });
      }
    },
    notiSearchFilter() {
      return this.notificationList.filter((el) => {
        return el.title.toUpperCase().includes(this.searchNotifification);
      });
    },
  },
  created() {
    this.$store.dispatch("getNotification");
  },
};
</script>
<style>
.notiPointer {
  cursor: pointer !important;
}
</style>
