import { loginService } from "../../services/login.services";
import { commonService } from "@/services/common.service";
import errorHandling from "../../handleError/errHandling";
import router from "../../router/index";
const state = {
  errorMessage: "",
  step: "",
  deviceId: "",
  resetStage: false,
  currentMobileNo: "",
  currentEmailId: "",
  loader: false,
  stage: "mobileNo",
  source: "WEB",
  submitted: false,
  userRole: "",
  ssoQuery: "",
  //
  loginData: "",
  accessToken: "",
  currentUserId: "",
  currentPanNumber: "",
  authToken: "",
  isExistUser: false,
  scannerData: "",
  UCC: "",
  vendorDetails: "",
  currentAppcode: "",
  password: "",
  authorizeLoader: false,
  vendorLoader: false,
};

const actions = {
  async authorizeVendor({ commit, dispatch }, payload) {
    commit("setAuthorizeLoader", true);
    await commonService
      .authorizeVendor(payload)
      .then(
        (res) => {
          if (
            res.status == 200 &&
            res.data.message == "Success" &&
            res.data.status == "Ok" &&
            res.data.result?.length > 0
          ) {
            let isSelf = router.currentRoute?.path == "/" ? true : false;

            dispatch("navigateVendor", {
              url: res.data.result[0].redirectUrl,
              isSelf: isSelf,
              isAppend: true,
            });
          } else {
            errorHandling.toaster(
              "",
              "danger",
              res.data?.message,
              3000,
              "top-right",
              " ",
              " "
            );
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setAuthorizeLoader", false);
        commit("setIsAuthDialog", false, { root: true });
      });
  },
  navigateVendor({ state, rootGetters }, payload) {
    let where = payload.isSelf ? "_self" : "_blank";
    let url = payload.url;
    if (payload.isAppend && state.ssoQuery) {
      for (let query in state.ssoQuery) {
        url += `&${query}=${state.ssoQuery[query]}`;
      }
    }

    window.open(url, where);
  },
  async getUserprofile({ state, dispatch, rootGetters }) {
    await commonService
      .getUserProfile()
      .then(
        (response) => {
          if (
            response.status == 200 &&
            response.data.message == "Success" &&
            response.data?.result
          ) {
            if (state.ssoQuery) {
              dispatch("getUrlOfVendor");
            } else {
              router.push("/home").catch(() => {});
            }
          } else {
            router.currentRoute.path == "/"
              ? errorHandling.resetLocal(false)
              : errorHandling.toaster(
                  "",
                  "danger",
                  response.data.message,
                  3000,
                  "top-right",
                  " ",
                  " "
                );
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {});
  },
  async validateVendor({ state, commit, dispatch, rootGetters }, payload) {
    let json = {
      vendor:
        payload.action == "initial" ? state.ssoQuery.appcode : payload.appcode,
    };
    if (payload?.stage && payload.stage == "Deposit") {
      commit("setVendorLoader", true);
    }

    await commonService
      .getAppDetails(json)
      .then(
        (response) => {
          if (response.status == 200 && response.data.message == "Success") {
            commit("setVendorDetails", response.data.result[0]);
            commit("setCurrentAppCode", json.vendor);

            if (state.UCC && state.accessToken) {
              payload == "initial"
                ? dispatch("getUserprofile")
                : dispatch("getUrlOfVendor");
            }
          } else {
            errorHandling.toaster(
              "",
              "danger",
              response.data.message,
              3000,
              "top-right",
              " ",
              " "
            );

            payload == "initial" ? router.push("/") : "";
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setVendorLoader", false);
      });
  },
  async getUrlOfVendor({ state, commit, dispatch }) {
    let json = {
      userId: state.UCC,
      vendor: state.currentAppcode,
    };
    commit("setVendorLoader", true);
    commonService
      .getVendorAccess(json)
      .then(
        (response) => {
          if (
            response.status == 200 &&
            response.data.status == "Ok" &&
            response.data.result?.length > 0
          ) {
            if (
              response.data.result[0].authorized &&
              response.data.result[0].redirectUrl
            ) {
              let isSelf = router.currentRoute.path == "/" ? true : false;
              dispatch("navigateVendor", {
                url: response.data.result[0].redirectUrl,
                isSelf: isSelf,
                isAppend: true,
              });
            }

            if (!response.data.result[0].authorized) {
              router.currentRoute.path == "/"
                ? commit("setStage", "authorize")
                : commit("setIsAuthDialog", true, { root: true });
            }
          } else {
            dispatch(
              "errorHandle/toaster",
              {
                data: {
                  title: "Failed",
                  type: "danger",
                  message: response.data.message,
                  duration: 4500,
                },
                position: "",
              },
              { root: true }
            );
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setVendorLoader", false);
      });
  },
  async verifyClient({ state, commit, dispatch, rootState }, payload) {
    commit("setLoader", true);
    let json = {
      userId: payload.mobileNo,
    };
    commit("setCurrentMobileNo", payload.mobileNo);
    await loginService
      .verifyClient(json)
      .then(
        async (response) => {
          if (
            response.status == 200 &&
            response.data.message == "Success" &&
            response.data?.result
          ) {
            let isExistUser = response.data?.result[0]?.isExist == "Yes";
            let UCC = response.data?.result[0]?.ucc;
            commit("setIsExistUser", isExistUser);
            commit("setUCC", UCC);
            //
            await commit("setCurrentUserId", UCC);
            if (!isExistUser) {
              // TODO handle ekyc
              commit("setErrorMessage", "Not a Valid User");
            } else {
              commit("setStage", "password");
              commit("setSubmitted", false);
            }
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },



  async validatePassword({ state, commit, dispatch, rootState }, payload) {
    commit("setLoader", true);
    if (payload) {
      state.UCC = state.UCC || payload?.mobileNo;
      state.password = payload?.password;
    }

    let json = {
      userId: state.UCC,
      source: state.source,
      password: state.password,
    };
    loginService
      .sendWebAuthOtp(json)
      .then(
        (response) => {
          commit("setCurrentMobileNo", state.UCC);
          if (
            response.status == 200 &&
            response.data.status == "Ok" &&
            response.data.message == "OTP generation success"
          ) {
            dispatch("loginWithOTP");
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async sendWebAuthOtp({ state, commit, dispatch, rootState }, payload) {
    if (payload?.type != "resend") {
      commit("setLoader", true);
    }
    let json = {
      userId: state.UCC,
      source: state.source,
    };
    await loginService
      .sendWebAuthOtp(json)
      .then(
        async (response) => {
          if (response.status == 200 && response.data.status == "Ok") {
            commit("setSubmitted", false);
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async verifyWebAuthOtp({ state, commit, dispatch, rootState }, payload) {
    commit("setLoader", true);
    let json = {
      userId: state.UCC,
      source: state.source,
      otp: payload.otp,
      deviceId: state.deviceId,
    };

    state.ssoQuery.appcode ? (json.vendor = state.ssoQuery.appcode) : "";

    await loginService
      .verifyWebAuthOtp(json)
      .then(
        async (response) => {
          if (response.status == 200 && response.data.message == "Success") {
            await commit("setLoginData", response.data?.result);
            commit("setSubmitted", false);
            let temp = Object.keys(response.data.result[0]);
            response.data.result.forEach((el) => {
              if (!!state.ssoQuery && temp.includes("authorized")) {
                if (el.authorized && el.redirectUrl) {
                  dispatch("navigateVendor", {
                    url: response.data.result[0].redirectUrl,
                    isSelf: true,
                    isAppend: true,
                  });
                } else {
                  if (el.isUpdate == 0) {
                    commit("setStage", "authorize");
                  } else {
                    commit("setStage", "resetPassword");
                  }
                }
              } else {
                if (el.isUpdate == 0) {
                  dispatch("callNotifyPop", "", { root: true });
                  dispatch("getUserprofile");
                } else {
                  commit("setStage", "resetPassword");
                }
              }
            });
          } else if (
            response.status == 200 &&
            response.data.status == "Not ok" &&
            response.data.message == "Invalid Input : Change Password"
          ) {
            commit("setStage", "resetPassword");
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async forgotPass({ state, commit, dispatch, rootState }, payload) {
    if (payload?.type != "resend") {
      commit("setLoader", true);
    }
    let json = {
      // source: state.source,
      pan: payload.pan,
      userId: payload.uid,
      dateOfBirth: payload.dateOfBirth,
    };
    commit("setCurrentMobileNo", payload.mobileNo);
    commit("setCurrentPanNumber", payload.panNumber);
    await loginService
      .forgotPass(json)
      .then(
        async (response) => {
          if (
            response.status == 200 &&
            response.data.status == "Ok" &&
            response.data.message == "New password is send through mail / sms"
          ) {
            await dispatch("verifyClient", {
              mobileNo: payload.uid,
            });
            commit("setSubmitted", false);

            errorHandling.toaster(
              "",
              "success",
              response.data.message,
              5000,
              "top-right",
              " ",
              " "
            );
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async forgotPassVerify({ state, commit, dispatch, rootState }, payload) {
    commit("setLoader", true);
    let json = {
      source: state.source,
      otp: payload.otp,
      userId: state.UCC,
    };
    await loginService
      .forgotPassVerify(json)
      .then(
        (response) => {
          if (response.status == 200 && response.data.message == "Success") {
            commit("setAuthToken", response.data.result[0]?.token);
            commit("setStage", "resetPass");
            commit("setSubmitted", false);
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async unblockAcc({ state, commit, dispatch, rootState }, payload) {
    if (payload?.type != "resend") {
      commit("setLoader", true);
    }
    let json = {
      dateOfBirth: payload.dateOfBirth,
      pan: payload.panNumber,
      userId: payload.userId,
    };
    commit("setCurrentMobileNo", payload.mobileNo);
    commit("setCurrentPanNumber", payload.panNumber);
    await loginService
      .unblockAcc(json)
      .then(
        (response) => {
          if (response.status == 200 && response.data.status == "Ok") {
            commit("setStage", "password");
            commit("setSubmitted", false);
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async unblockAccVerify({ state, commit, dispatch, rootState }, payload) {
    commit("setLoader", true);
    let json = {
      source: state.source,
      otp: payload.otp,
      userId: state.UCC,
    };
    await loginService
      .unblockAccVerify(json)
      .then(
        (response) => {
          if (
            response.status == 200 &&
            response.data.status == "Ok" &&
            response.data.message == "User unblocked sucessfully"
          ) {
            commit("setStage", "password");
            commit("setSubmitted", false);

            errorHandling.toaster(
              "",
              "success",
              response.data.message,
              3000,
              "top-right",
              " ",
              " "
            );
            alert(response.data.message);
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async resetPassword({ state, commit, dispatch, rootGetters }, payload) {
    commit("setLoader", true);
    let json = {
      userId: state.UCC,
      password: payload.password,
    };
    await loginService
      .resetPassword(json)
      .then(
        (response) => {
          if (response.status == 200 && response.data.status == "Ok") {
            commit("setStage", "password");
            commit("setSubmitted", false);
            errorHandling.toaster(
              "",
              "success",
              response.data.message,
              3000,
              "top-right",
              " ",
              " "
            );
            alert(response.data.message);
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async registerTotp({ state, commit, dispatch, rootGetters }) {
    let json = {
      userId: state.UCC,
    };
    await loginService
      .registerTotp(json)
      .then(
        (response) => {
          if (response.status == 200 && response.data.status == "Ok") {
            //commit("setStage", "verifyScannerOtp");
            commit("setSubmitted", false);
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async getScannerData({ state, commit, dispatch, rootGetters }, payload) {
    commit("setLoader", true);
    let json = {
      userId: state.UCC,

      otp: payload.otp,
    };
    await loginService
      .getScannerData(json)
      .then(
        (response) => {
          if (response.status == 200 && response.data.status == "Ok") {
            commit("setScannerData", response.data.result[0]);
            commit("setStage", "enableTotp");
            commit("setSubmitted", false);
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async enableTotp({ state, commit, dispatch, rootGetters }, payload) {
    commit("setLoader", true);
    let json = {
      userId: state.UCC,
      source: state.source,
      totp: payload.totp,
    };
    await loginService
      .enableTotp(json)
      .then(
        (response) => {
          if (response.status == 200 && response.data.message == "Success") {
            commit("setStage", "totp");
            commit("setSubmitted", false);
          } else {
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async verifyTotp({ state, commit, dispatch, rootGetters }, payload) {
    commit("setLoader", true);
    let json = {
      userId: state.UCC,
      source: state.source,
      totp: payload.totp,
    };
    await loginService
      .verifyTotp(json)
      .then(
        async (response) => {
          if (response.status == 200 && response.data.status == "Ok") {
            await commit("setLoginData", response.data?.result);
            await dispatch("callNotifyPop", "", { root: true });
            await dispatch("getUserprofile");
            commit("setSubmitted", false);
          } else {
            commit("setLoader", false);
            commit("setSubmitted", true);
            commit("setErrorMessage", response.data.message);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )
      .finally(() => {
        commit("setLoader", false);
      });
  },

  async loginWithOTP({ commit, dispatch }, payload) {
    commit("setErrorMessage", "");
    commit("setStage", "verifyWebAuthOtp");
    // dispatch("sendWebAuthOtp", payload);
  },
};

const mutations = {
  resetState(state) {
    state.errorMessage = "";
    state.step = "";
    state.deviceId = "";
    state.currentMobileNo = "";
    state.currentEmailId = "";
    state.loader = false;
    state.stage = "mobileNo";
    state.source = "WEB";
    state.submitted = false;
    state.userRole = "";
    state.ssoQuery = "";

    state.loginData = "";
    state.accessToken = "";
    state.currentUserId = "";
    state.currentPanNumber = "";
    state.authToken = "";
    state.isExistUser = false;
    state.scannerData = "";
    state.UCC = "";
    state.vendorDetails = "";
    state.currentAppcode = "";
  },
  setDeviceId(state, payload) {
    state.deviceId = payload;
  },
  setResetStage(state, payload) {
    state.resetStage = payload;
    localStorage.setItem("resetStage", JSON.stringify(payload));
  },
  setStepAction(state, payload) {
    state.step = payload;
    localStorage.setItem("step", JSON.stringify(payload));
  },
  setVendorDetails(state, payload) {
    state.vendorDetails = payload;
  },
  setCurrentAppCode(state, payload) {
    state.currentAppcode = payload;
  },
  setSsoQuery(state, payload) {
    state.ssoQuery = payload;
  },
  setErrorMessage(state, payload) {
    state.errorMessage = payload;
  },
  setCurrentMobileNo(state, payload) {
    state.currentMobileNo = payload;
  },
  setCurrentEmailId(state, payload) {
    state.currentEmailId = payload;
    localStorage.setItem("mailId", JSON.stringify(payload));
  },
  setLoader(state, payload) {
    state.loader = payload;
  },
  setStage(state, payload) {
    state.stage = payload;
    localStorage.setItem("stage", JSON.stringify(payload));
  },
  setSubmitted(state, payload) {
    state.submitted = payload;
  },
  setUserRole(state, payload) {
    state.userRole = payload;
    localStorage.setItem("role", JSON.stringify(payload));
  },
  setLoginData(state, payload) {
    state.loginData = payload;
    state.accessToken = payload[0]?.accessToken;

    localStorage.setItem("accessToken", JSON.stringify(payload));
  },
  setCurrentUserId(state, userId) {
    state.currentUserId = userId;
    localStorage.setItem("userId", JSON.stringify(userId));
  },
  setCurrentPanNumber(state, payload) {
    state.currentPanNumber = payload;
    localStorage.setItem("pan", JSON.stringify(payload));
  },
  setAuthToken(state, payload) {
    state.authToken = payload;
  },
  setIsExistUser(state, payload) {
    state.isExistUser = payload;
    localStorage.setItem("isExistUser", JSON.stringify(payload));
  },
  setScannerData(state, payload) {
    state.scannerData = payload;
  },
  setUCC(state, payload) {
    state.UCC = payload;
    localStorage.setItem("UCC", JSON.stringify(payload));
  },
  setAuthorizeLoader(state, payload) {
    state.authorizeLoader = payload;
  },
  setVendorLoader(state, payload) {
    state.vendorLoader = payload;
  },
};

const getters = {
  loader: (state) => state.loader,
  stage: (state) => state.stage,
  submitted: (state) => state.submitted,
  currentMobileNo: (state) => state.currentMobileNo,
  currentEmailId: (state) => state.currentEmailId,
  userRole: (state) => state.userRole,
  errorMessage: (state) => state.errorMessage,

  //
  getLoginData: (state) => state.loginData,
  getAccessToken: (state) => state.accessToken,
  getCurrentUserId: (state) => state.currentUserId,
  currentPanNumber: (state) => state.currentPanNumber,
  getIsExistUser: (state) => state.isExistUser,
  //
  getScannerData: (state) => state.scannerData,
  getUCC: (state) => state.UCC,
};

const login = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default login;
