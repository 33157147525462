import store from "../store/index.js";
export function authHeader() {
  let accessToken = store.state.login.accessToken;
  let headers;
  if (accessToken) {
    headers = { Authorization: "Bearer " + accessToken };
  } else {
    headers = getContentType();
  }
  return headers;
}

export function loginAuthHead() {
  let token = store.state.login.authToken;
  let ucc = store.state.login.UCC;
  let authHeader;
  if (token && ucc) {
    authHeader = { Authorization: `Bearer ${ucc} WEB ${token}` };
  } else {
    authHeader = getContentType();
  }
  return authHeader;
}

export function getHeaders(isAuth, isPlain, isNormal) {
  let requestOptions = {
    headers: "",
  };
  if (isNormal) {
    requestOptions.headers = getContentType();
  } else if (isPlain) {
    requestOptions.headers = getPlainType();
  } else {
    requestOptions.headers = isAuth ? authHeader() : loginAuthHead();
  }
  return requestOptions;
}

function getContentType() {
  return {
    "Content-Type": "application/json",
  };
}

function getPlainType() {
  return {
    "Content-Type": "text/plain",
  };
}
