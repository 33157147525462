<template>
  <draggable
    :disabled="$store.state.isMobileView"
    class="w-100"
    @start="drag = true"
    :list="watchData"
    @end="(drag = false), sortList"
  >
    <v-expansion-panel
      tile
      class="ma-0 hoverparent"
      v-for="(item, index) in watchData"
      :key="index"
      :id="`${item.formattedInsName}_watch_scrip_ele`"
      :name="`${item.formattedInsName}_Container`"
    >
      <v-expansion-panel-header
        hide-actions
        @mouseenter="currentIndex = index"
        @mouseleave="currentIndex = -1"
        @click="$store.state.isMobileView ? callBottomSheet(item, index) : ''"
      >
        <div class="d-flex justify-space-between align-center pb-2">
          <span class="currentToken">{{ currentToken }}</span>

          <span
            class="primaryColor"
            :id="`${item.formattedInsName}_scrip_name`"
            :name="`${item.formattedInsName}`"
            :data-font-size="tableFontSize"
          >
            {{ item.formattedInsName }}
          </span>
          <span
            :id="`${item.formattedInsName}_scrip_lp`"
            :name="`${item.ltp}`"
            :class="
              parseFloat(item.pc) == 0
                ? 'primaryColor'
                : parseFloat(item.pc) > 0
                ? 'positiveColor'
                : 'negativeColor'
            "
          >
            {{
              item.ltp
                ? item.exchange == "CDS" || item.exchange == "BCD"
                  ? parseFloat(item.ltp).toFixed(4)
                  : parseFloat(item.ltp).toFixed(2)
                : "0.00"
            }}</span
          >
        </div>
        <div
          class="d-flex justify-space-between align-center primaryColor"
          :sub-data-font-size="tableFontSize"
        >
          <span
            class="secondaryColor d-flex align-center"
            :id="`${item.formattedInsName}_scrip_ex`"
            :name="`${item.exchange}`"
            >{{ item.exchange }}
            <span class="ml-1" v-if="item.holFlag == 1">
              <customIcon
                class="d-flex align-center"
                :name="'holdings'"
                :width="'12'"
                :height="'12'"
                :color="
                  $vuetify.theme.dark
                    ? '#ffffff'
                    : $store.state.iconBlackColourCode
                "
              />
            </span>
            <span
              :id="`${item.formattedInsName}_hold_flag`"
              :name="`${item.holdQty}`"
              class="ml-1"
              v-if="item.holFlag == 1"
              >{{ item.holdQty }}</span
            ></span
          >
          <span
            :id="`${item.formattedInsName}_scrip_chg`"
            :name="`${item.chg}`"
            :class="
              parseFloat(item.pc) == 0
                ? 'primaryColor'
                : parseFloat(item.pc) > 0
                ? 'positiveColor'
                : 'negativeColor'
            "
            >{{ parseFloat(item.chg).toFixed(2) }}
            <span :id="`${item.formattedInsName}_scrip_pc`" :name="`${item.pc}`"
              >({{ item.pc }}%)</span
            ></span
          >
        </div>
        <div
          class="hoverbtns"
          v-if="currentIndex == index && !$store.state.isMobileView"
        >
          <div class="d-flex justify-end">
            <div class="d-flex align-center absolute">
              <v-tooltip
                top
                :color="
                  $vuetify.theme.dark
                    ? 'darktoolTipColor toolTipTop darkTooltipTop'
                    : 'lighttoolTipColor toolTipTop lightTooltipTop'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    :id="`${item.formattedInsName}_scrip_buy`"
                    name="Buy"
                    class="hoverBtn mr-2 buy"
                    v-if="
                      item.segment && !item.segment.includes('_idx')
                    "
                    @click="
                      callOrderWindowByContract(
                        item,
                        'buy',
                        false,
                        'mkWatch',
                        false,
                        false,
                        false,
                        false
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                    >B</span
                  >
                </template>
                <span>Buy</span>
              </v-tooltip>

              <v-tooltip
                top
                :color="
                  $vuetify.theme.dark
                    ? 'darktoolTipColor toolTipTop darkTooltipTop'
                    : 'lighttoolTipColor toolTipTop lightTooltipTop'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    :id="`${item.formattedInsName}_scrip_sell`"
                    name="'Sell'"
                    class="hoverBtn mr-2 sell"
                    v-if="
                      item.segment && !item.segment.includes('_idx')
                    "
                    @click="
                      callOrderWindowByContract(
                        item,
                        'sell',
                        false,
                        'mkWatch',
                        false,
                        false,
                        false,
                        false
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                    >S</span
                  >
                </template>
                <span>Sell</span>
              </v-tooltip>

              <v-tooltip
                top
                :color="
                  $vuetify.theme.dark
                    ? 'darktoolTipColor toolTipTop darkTooltipTop'
                    : 'lighttoolTipColor toolTipTop lightTooltipTop'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    :id="`${item.formattedInsName}_scrip_chart`"
                    name="Chart"
                    class="hoverBtn mr-2"
                    :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'"
                    v-bind="attrs"
                    v-on="on"
                    @click="callTrandingViewChart(item, 'mkWatch')"
                  >
                    <customIcon
                      class="hoverImg d-flex align-center"
                      :name="'chart'"
                      :width="'24'"
                      :height="'24'"
                      :color="
                        $vuetify.theme.dark
                          ? '#ffffff'
                          : $store.state.iconBlackColourCode
                      "
                    />
                  </span>
                </template>
                <span>Chart</span>
              </v-tooltip>

              <v-tooltip
                top
                :color="
                  $vuetify.theme.dark
                    ? 'darktoolTipColor toolTipTop darkTooltipTop'
                    : 'lighttoolTipColor toolTipTop lightTooltipTop'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    :id="`${item.formattedInsName}_scrip_depth`"
                    name="Depth"
                    class="hoverBtn mr-2"
                    :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'"
                    v-bind="attrs"
                    v-on="on"
                    @click="openPanel(index, item, watchData)"
                  >
                    <customIcon
                      class="hoverImg d-flex align-center"
                      :name="'depth'"
                      :width="'20'"
                      :height="'20'"
                      :color="
                        $vuetify.theme.dark
                          ? '#ffffff'
                          : $store.state.iconBlackColourCode
                      "
                    />
                  </span>
                </template>
                <span>Market Depth</span>
              </v-tooltip>

              <v-tooltip
                v-if="currentMwTab == 'Watchlist'"
                top
                :color="
                  $vuetify.theme.dark
                    ? 'darktoolTipColor toolTipTop darkTooltipTop'
                    : 'lighttoolTipColor toolTipTop lightTooltipTop'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    :id="`${item.formattedInsName}_scrip_del`"
                    name="Delete"
                    class="hoverBtn mr-2"
                    :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'"
                    v-bind="attrs"
                    v-on="on"
                    @click="addorDeleteScrip(item, 'del')"
                  >
                    <customIcon
                      class="hoverImg d-flex align-center"
                      :name="'delete'"
                      :width="'20'"
                      :height="'20'"
                      :color="
                        $vuetify.theme.dark
                          ? '#ffffff'
                          : $store.state.iconBlackColourCode
                      "
                    />
                  </span>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <div style="width: 24px">
                <v-tooltip
                  top
                  :color="
                    $vuetify.theme.dark
                      ? 'darktoolTipColor toolTipTop darkTooltipTop'
                      : 'lighttoolTipColor toolTipTop lightTooltipTop'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="checkVisiblity(item)"
                      @click="
                        moreIndexData(index),
                          getwindowheight(
                            `${item.formattedInsName}_scrip_opt`,
                            'opt'
                          )
                      "
                      :id="`${item.formattedInsName}_scrip_opt`"
                      name="More"
                      :class="[
                        $vuetify.theme.dark ? 'darkHover' : 'lightHover',
                        getFontSize(12),
                      ]"
                      min-width="30"
                      max-width="35"
                      height="25"
                      outlined
                      color="grey"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      class="hoverBtn d-flex align-center mr-2 text-capitalize"
                    >
                      <customIcon
                        @mouseleave="moreIndex = -1"
                        class="d-flex align-center"
                        :name="'more'"
                        :width="'24'"
                        :height="'24'"
                        :color="
                          $vuetify.theme.dark
                            ? '#ffffff'
                            : $store.state.iconBlackColourCode
                        "
                      />
                    </v-btn>
                  </template>
                  <span>More</span>
                </v-tooltip>
                <transition name="slide" :id="`${index}_tttt`">
                  <ul
                    @click="
                      getwindowheight(
                        `${item.formattedInsName}_scrip_opt_ul`,
                        'opt'
                      )
                    "
                    :id="`${item.formattedInsName}_scrip_opt_ul`"
                    :class="isRotate ? 'bottom25' : ''"
                    v-if="moreIndex == index"
                    @mouseleave="moreIndex = -1"
                    class="pa-0 list mkWatchMoreList alignDropDown"
                  >
                    <li
                      v-if="
                        item.segment && !item.segment.includes('_idx')
                      "
                      @click="openAlertDialog(item)"
                      :id="`${item.formattedInsName}_scrip_opt_li_alert`"
                      name="Alert"
                      class="primaryColor cursor"
                      :class="getFontSize(12)"
                    >
                      <div class="d-flex align-center">
                        <img
                          src="../../assets/dashboardImages/alert_icon.svg"
                          alt=""
                          width="20px"
                          height="20px"
                        />
                        <span class="ml-3">Alert</span>
                      </div>
                    </li>
                    <li
                      v-if="
                        item.segment && !item.segment.includes('_idx')
                      "
                      @click="openInfoDialog(item)"
                      :id="`${item.formattedInsName}_scrip_opt_li_info`"
                      name="Info"
                      class="primaryColor cursor"
                      :class="getFontSize(12)"
                    >
                      <div class="d-flex align-center">
                        <customIcon
                          class="d-flex align-center"
                          :name="'info'"
                          :width="'20'"
                          :height="'20'"
                          :color="
                            $vuetify.theme.dark
                              ? '#ffffff'
                              : $store.state.iconBlackColourCode
                          "
                        />
                        <span class="ml-3">Info</span>
                      </div>
                    </li>
                    <li
                      v-if="
                        item.segment && !item.segment.includes('_idx') && item.exchange != 'CDS' && item.exchange != 'BFO' && item.exchange != 'BCD'
                      "
                      @click="openGtt(item)"
                      :id="`${item.formattedInsName}_scrip_opt_li_gtt`"
                      name="GTT"
                      class="primaryColor cursor"
                      :class="getFontSize(12)"
                    >
                      <div class="d-flex align-center">
                        <img
                          src="../../assets/images/gtt.png"
                          alt=""
                          width="20px"
                          height="20px"
                          class="d-flex align-center"
                        />
                        <span class="ml-3">Create GTT</span>
                      </div>
                    </li>
                    <li
                      @click="callOptionChain(item)"
                      v-if="
                        item.exchange == 'NFO' ||
                        item.exchange == 'BFO' ||
                        item.exchange == 'MCX' ||
                        item.exchange == 'CDS'
                      "
                      :id="`${item.formattedInsName}_scrip_opt_li_option_chain`"
                      name="OC"
                      class="primaryColor cursor"
                      :class="getFontSize(12)"
                    >
                      <div class="d-flex align-center">
                        <img
                          src="../../assets/dashboardImages/option_chain_new.svg"
                          alt=""
                          width="20px"
                          height="20px"
                        />
                        <span class="ml-3">Option Chain</span>
                      </div>
                    </li>
                  </ul>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </v-expansion-panel-header>

      <div>
        <v-sheet
          v-if="depthIndex == index"
          class="mkdepth"
          :id="`${item.formattedInsName}_scrip_depth`"
        >
          <div
            flat
            class="d-flex align-center px-2 pt-2 pb-1"
            :class="getFontSize(12)"
          >
            <div class="w-25 text-center">
              <p class="mb-1 secondaryColor">Open</p>
              <p
                class="mb-1 primaryColor"
                :id="`${item.formattedInsName}_scrip_o`"
              >
                {{ setTofixed(formatNum(item.o), index) }}
              </p>
            </div>
            <div class="w-25 text-center" :class="getFontSize(12)">
              <p class="mb-1 secondaryColor">High</p>
              <p
                class="mb-1 primaryColor"
                :id="`${item.formattedInsName}_scrip_h`"
              >
                {{ setTofixed(formatNum(item.h), index) }}
              </p>
            </div>
            <div class="w-25 text-center" :class="getFontSize(12)">
              <p class="mb-1 secondaryColor">Low</p>
              <p
                class="mb-1 primaryColor"
                :id="`${item.formattedInsName}_scrip_l`"
              >
                {{ setTofixed(formatNum(item.l), index) }}
              </p>
            </div>
            <div class="w-25 text-center" :class="getFontSize(12)">
              <p class="mb-1 secondaryColor">Close</p>
              <p
                class="mb-1 primaryColor"
                :id="`${item.formattedInsName}_scrip_c`"
              >
                {{ setTofixed(formatNum(item.c), index) }}
              </p>
            </div>
          </div>
          <div
            class="rowMk ma-0 pa-1 d-flex"
            v-if="item.segment && !item.segment.includes('_idx')"
          >
            <table
              class="w-50 col-6 pa-0 buyTable wrap"
              id="buyTable_market_depth"
            >
              <thead id="buyTable_market_depth_head">
                <tr :class="getFontSize(10)">
                  <th
                    id="buyTable_market_depth_Bid"
                    class="font-weight-normal primaryColor pb-2 pr-4 text-left"
                  >
                    BID
                  </th>
                  <th
                    id="buyTable_market_depth_orders"
                    class="font-weight-normal primaryColor pb-2 pr-2 text-right"
                  >
                    ORDERS
                  </th>
                  <th
                    id="buyTable_market_depth_qty"
                    class="font-weight-normal primaryColor pb-2 pr-2 text-right"
                  >
                    QTY
                  </th>
                </tr>
              </thead>
              <tbody id="buyTable_market_depth_body" :class="getFontSize(12)">
                <tr>
                  <td
                    class="text-left pb-1 pr-4"
                    :id="`${item.formattedInsName}_scrip_bp1`"
                  >
                    {{ formatNum(item.bp1) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_bo1`"
                  >
                    {{ item?.bo1 ? item.bo1 : "0.00" }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_bq1`"
                  >
                    {{ item?.bq1 ? item.bq1 : "0.00" }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-left pb-1 pr-4"
                    :id="`${item.formattedInsName}_scrip_bp2`"
                  >
                    {{ formatNum(item.bp2) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_bo2`"
                  >
                    {{ formatNum(item.bo2) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_bq2`"
                  >
                    {{ formatNum(item.bq2) }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-left pb-1 pr-4"
                    :id="`${item.formattedInsName}_scrip_bp3`"
                  >
                    {{ formatNum(item.bp3) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_bo3`"
                  >
                    {{ formatNum(item.bo3) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_bq3`"
                  >
                    {{ formatNum(item.bq3) }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-left pb-1 pr-4"
                    :id="`${item.formattedInsName}_scrip_bp4`"
                  >
                    {{ formatNum(item.bp4) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_bo4`"
                  >
                    {{ formatNum(item.bo4) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_bq4`"
                  >
                    {{ formatNum(item.bq4) }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-left pb-1 pr-4"
                    :id="`${item.formattedInsName}_scrip_bp5`"
                  >
                    {{ formatNum(item.bp5) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_bo5`"
                  >
                    {{ formatNum(item.bo5) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_bq5`"
                  >
                    {{ formatNum(item.bq5) }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr :class="getFontSize(12)">
                  <td class="text-left py-1 pr-4">Total</td>
                  <td
                    colspan="2"
                    class="text-right py-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_tot_buy`"
                  >
                    {{ ruppesFormat(formatNum(item.tbq)) }}
                  </td>
                </tr>
              </tfoot>
            </table>
            <table
              class="w-50 col-6 pa-0 sellTable wrap"
              id="sellTable_market_depth"
            >
              <thead id="sellTable_market_depth_head">
                <tr :class="getFontSize(10)">
                  <th
                    id="sellTable_market_depth_offer"
                    class="font-weight-normal primaryColor pb-2 pr-4 text-left"
                  >
                    OFFER
                  </th>
                  <th
                    id="sellTable_market_depth_orders"
                    class="font-weight-normal primaryColor pb-2 pr-2 text-right"
                  >
                    ORDERS
                  </th>
                  <th
                    id="sellTable_market_depth_qty"
                    class="font-weight-normal primaryColor pb-2 pr-2 text-right"
                  >
                    QTY
                  </th>
                </tr>
              </thead>
              <tbody id="sellTable_market_depth_body" :class="getFontSize(12)">
                <tr>
                  <td
                    class="text-left pb-1 pr-4"
                    :id="`${item.formattedInsName}_scrip_sp1`"
                  >
                    {{ formatNum(item.sp1) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_so1`"
                  >
                    {{ formatNum(item.so1) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_sq1`"
                  >
                    {{ formatNum(item.sq1) }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-left pb-1 pr-4"
                    :id="`${item.formattedInsName}_scrip_sp2`"
                  >
                    {{ formatNum(item.sp2) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_so2`"
                  >
                    {{ formatNum(item.so2) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_sq2`"
                  >
                    {{ formatNum(item.sq2) }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-left pb-1 pr-4"
                    :id="`${item.formattedInsName}_scrip_sp3`"
                  >
                    {{ formatNum(item.sp3) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_so3`"
                  >
                    {{ formatNum(item.so3) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_sq3`"
                  >
                    {{ formatNum(item.sq3) }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-left pb-1 pr-4"
                    :id="`${item.formattedInsName}_scrip_sp4`"
                  >
                    {{ formatNum(item.sp4) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_so4`"
                  >
                    {{ formatNum(item.so4) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_sq4`"
                  >
                    {{ formatNum(item.sq4) }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-left pb-1 pr-4"
                    :id="`${item.formattedInsName}_scrip_sp5`"
                  >
                    {{ formatNum(item.sp5) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_so5`"
                  >
                    {{ formatNum(item.so5) }}
                  </td>
                  <td
                    class="text-right pb-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_sq5`"
                  >
                    {{ formatNum(item.sq5) }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr :class="getFontSize(12)">
                  <td class="text-left py-1 pr-4">Total</td>
                  <td
                    colspan="2"
                    class="text-right py-1 pr-2"
                    :id="`${item.formattedInsName}_scrip_tot_sell`"
                  >
                    {{ ruppesFormat(formatNum(item.tsq)) }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div
            class="d-flex w-100 pa-2"
            :class="getFontSize(12)"
            v-if="item.segment && !item.segment.includes('_idx')"
          >
            <div class="w-50 pr-2">
              <div class="d-flex justify-space-between">
                <div class="secondaryColor pb-1">Volume</div>
                <div
                  class="primaryColor pb-1"
                  :id="`${item.formattedInsName}_scrip_v`"
                >
                  {{ convertLacs(formatNum(item.v), item.avg_prc) }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div class="secondaryColor pb-1">52 Week High</div>
                <div
                  class="primaryColor pb-1"
                  :id="`${item.formattedInsName}_scrip_v`"
                >
                  {{ item.yh }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div class="secondaryColor pb-1">LTQ</div>
                <div
                  class="primaryColor pb-1"
                  :id="`${item.formattedInsName}_scrip_ltq`"
                >
                  {{ item?.ltq && item.ltq != "0.00" ? item.ltq : "0" }}
                </div>
              </div>
              <div
                class="d-flex justify-space-between"
                v-if="item.exchange != 'NSE' && item.exchange != 'BSE'"
              >
                <div class="secondaryColor pb-1">Expiry</div>
                <div
                  class="primaryColor pb-1"
                  :id="`${item.formattedInsName}_scrip_exDt`"
                >
                  {{ item.expiry ? formatDate(item.expiry) : "--" }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div class="secondaryColor pb-1">Lower Circuit</div>
                <div
                  class="primaryColor pb-1"
                  :id="`${item.formattedInsName}_scrip_lc`"
                >
                  {{ formatNum(item.lc) }}
                </div>
              </div>
            </div>
            <div class="w-50">
              <div class="d-flex justify-space-between">
                <div class="secondaryColor pb-1">Avg.Price</div>
                <div
                  class="primaryColor pb-1"
                  :id="`${item.formattedInsName}_scrip_avg`"
                >
                  {{ item?.avg_prc ? item.avg_prc : "0.00" }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div class="secondaryColor pb-1">52 Week Low</div>
                <div
                  class="primaryColor pb-1"
                  :id="`${item.formattedInsName}_scrip_v`"
                >
                  {{ item.yl }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div class="secondaryColor pb-1">LTT</div>
                <div
                  class="primaryColor pb-1"
                  :id="`${item.formattedInsName}_scrip_ltt`"
                >
                  {{ item?.ltt && item.ltt != "0.00" ? item.ltt : "NA" }}
                </div>
              </div>
              <div
                class="d-flex justify-space-between"
                v-if="item.exchange != 'NSE' && item.exchange != 'BSE'"
              >
                <div class="secondaryColor pb-1">OI</div>
                <div
                  class="primaryColor pb-1"
                  :id="`${item.formattedInsName}_scrip_oi`"
                >
                  {{ item?.oi ? item.oi : "0" }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div class="secondaryColor pb-1">Upper Circuit</div>
                <div
                  class="primaryColor pb-1"
                  :id="`${item.formattedInsName}_scrip_uc`"
                >
                  {{ formatNum(item.uc) }}
                </div>
              </div>
            </div>
          </div>
        </v-sheet>
      </div>
    </v-expansion-panel>
  </draggable>
</template>
<script>
import commonFunctions from "@/mixins/commonFunctions";
import gtt from "@/mixins/gtt";
import depth from "./depth.vue";
import draggable from "vuedraggable";
import { mapState, mapGetters, mapActions } from "vuex";
import formula from "@/mixins/formula";
export default {
  name: "listing",
  props: {
    watchData: Array,
    // tableFontSize: String,
    currentMwTab: String,
  },
  components: { depth, draggable },
  data: () => ({
    currentIndex: 0,
    moreIndex: -1,
    currentToken: "",
  }),
  computed: {
    ...mapState("marketWatch", [
      "watchlistData",
      "watchList",
      "tab",
      "depthIndex",
      "tableFontSize",
    ]),
    ...mapGetters("login", ["getUCC"]),
    ...mapActions("marketWatch", ["sortList"]),
  },
  mixins: [commonFunctions, gtt],
  methods: {
    checkVisiblity(item) {
      if (
        (item.segment && item.segment.includes('_idx')) &&
        (item.exchange != "NFO" || item.exchange != "MCX")
      ) {
        return false;
      } else {
        return true;
      }
    },
    setTofixed(val, index) {
      let exchange = this.watchData;
      return exchange == "CDS" || exchange == "BCD"
        ? parseFloat(val).toFixed(4)
        : parseFloat(val).toFixed(2);
    },
    formatDate(date) {
      let currentVal = date;
      currentVal = currentVal.split("-").reverse().join("-");
      return currentVal;
    },
    openInfoDialog(currentData) {
      // this.infoData = currentData;
      this.$store.commit("marketWatch/handleInfoDialog", true);
      this.$store.dispatch("marketWatch/getSecurityInfo", currentData);
    },
    openGtt(data) {
      window.goodtillWindow = this.listenGttTicks;
      this.callOrderWindowByContract(
        data,
        "buy",
        false,
        "gtt",
        false,
        false,
        false,
        true
      );
      this.$store.commit("gtt/setAction", data.token);
      this.$store.commit("gtt/setGttData", data);
      //this.$store.commit("gtt/setGttWindow", true);
      this.$store.commit("gtt/setGttStatus", true);
    },
    openAlertDialog(props) {
      this.alertCondition = true;
      this.alertDetails = props;
      this.$store.commit("order/openAlertDialog", {
        openCond: true,
        props: props,
        alertCallSource: "mkWatch",
      });
    },
    moreIndexData(index) {
      this.moreIndex == -1 ? (this.moreIndex = index) : (this.moreIndex = -1);
    },
    async addorDeleteScrip(data, type, isSingle, length) {
      // this.openPanel(-1, "item");

      let json = {
        userId: this.getUCC,

        scripData: [
          {
            exch: data.exchange,
            token: data.token,
          },
        ],
      };
      if (type == "del") {
        await this.$store.dispatch("marketWatch/deleteSearchList", {
          json,
          data,
        });
      } else if (type == "add" && length < 30) {
        await this.$store.dispatch("marketWatch/addSearchList", { json, data });
      } else if (type == "add" && length >= 30) {
        errorHandling.toaster(
          "",
          "danger",
          "Can't add more than 30 scrips.Use another tab",
          3000,
          "top-right",
          " ",
          " "
        );
      }
      if (isSingle) {
      }
      await this.$store.dispatch("marketWatch/changeWatchList").finally(() => {
        this.updateWatch();
      });
    },
    updateWatch() {
      this.watchData = this.watchlistData;
    },
    async openPanel(index, item, array) {
      //Reset Depth on tab change
      let dI = this.depthIndex;
      if (dI == index) {
        dI = -1;
      } else {
        dI = index;
      }

      this.$store.commit("marketWatch/setDepthIndex", dI);
      if (dI != -1) {
        if (item.segment && !item.segment.includes('_idx'))
          this.$store.dispatch(
            "wsConnection/depthSubscription",
            item.exchange + "|" + item.token
          );
      } else {
        this.$store.dispatch("wsConnection/depthSubscription", "");
      }
    },
    callBottomSheet(val, index) {
      this.currentSheetData = [];
      var tempData = {
        where: "MkWatch",
        isOpen: true,
        item: val,
      };
      this.currentSheetData.push(tempData);
      this.$emit("listSheetData", this.currentSheetData);
      this.$emit("listSheetIndex", index);
    },

    wsWatch() {
      if (window.tickers) {
        let tickerData = window.tickers;
        if (this.watchData) {
          this.watchData.forEach((scrip) => {
            let current = `${scrip.exchange}|${scrip.token}`;

            if (tickerData && current && tickerData[current]) {
              scrip.ltp = tickerData[current].lp
                ? tickerData[current].lp
                : tickerData[current].c
                ? tickerData[current].c
                : scrip.pdc;

              this.currentToken = current;
              Number(scrip.pdc) == 0 ? (scrip.pdc = tickerData[current].c) : "";

              tickerData[current].pc ? (scrip.pc = tickerData[current].pc) : "";

              scrip.o = tickerData[current].o ? tickerData[current].o : scrip.o;

              scrip.h = tickerData[current].h ? tickerData[current].h : scrip.h;
              scrip.l = tickerData[current].l ? tickerData[current].l : scrip.l;
              scrip.c = tickerData[current].c ? tickerData[current].c : scrip.c;
              scrip.chg = formula.methods.getChg(
                scrip.ltp,
                scrip.c,
                scrip.exchange
              );
              scrip.bp1 = tickerData[current].bp1
                ? tickerData[current].bp1
                : scrip.bp1;

              scrip.bp2 = tickerData[current].bp2
                ? tickerData[current].bp2
                : scrip.bp2;
              scrip.bp3 = tickerData[current].bp3
                ? tickerData[current].bp3
                : scrip.bp3;
              scrip.bp4 = tickerData[current].bp4
                ? tickerData[current].bp4
                : scrip.bp4;
              scrip.bp5 = tickerData[current].bp5
                ? tickerData[current].bp5
                : scrip.bp5;
              scrip.bo1 = tickerData[current].bo1
                ? tickerData[current].bo1
                : scrip.bo1;
              scrip.bo2 = tickerData[current].bo2
                ? tickerData[current].bo2
                : scrip.bo2;
              scrip.bo3 = tickerData[current].bo3
                ? tickerData[current].bo3
                : scrip.bo3;
              scrip.bo4 = tickerData[current].bo4
                ? tickerData[current].bo4
                : scrip.bo4;
              scrip.bo5 = tickerData[current].bo5
                ? tickerData[current].bo5
                : scrip.bo5;
              scrip.bq1 = tickerData[current].bq1
                ? tickerData[current].bq1
                : scrip.bq1;
              scrip.bq2 = tickerData[current].bq2
                ? tickerData[current].bq2
                : scrip.bq2;
              scrip.bq3 = tickerData[current].bq3
                ? tickerData[current].bq3
                : scrip.bq3;
              scrip.bq4 = tickerData[current].bq4
                ? tickerData[current].bq4
                : scrip.bq4;
              scrip.bq5 = tickerData[current].bq5
                ? tickerData[current].bq5
                : scrip.bq5;

              scrip.tbq = tickerData[current].tbq
                ? tickerData[current].tbq
                : scrip.tbq;

              scrip.sp1 = tickerData[current].sp1
                ? tickerData[current].sp1
                : scrip.sp1;
              scrip.sp2 = tickerData[current].sp2
                ? tickerData[current].sp2
                : scrip.sp2;
              scrip.sp3 = tickerData[current].sp3
                ? tickerData[current].sp3
                : scrip.sp3;
              scrip.sp4 = tickerData[current].sp4
                ? tickerData[current].sp4
                : scrip.sp4;
              scrip.sp5 = tickerData[current].sp5
                ? tickerData[current].sp5
                : scrip.sp5;

              scrip.so1 = tickerData[current].so1
                ? tickerData[current].so1
                : scrip.so1;
              scrip.so2 = tickerData[current].so2
                ? tickerData[current].so2
                : scrip.so2;
              scrip.so3 = tickerData[current].so3
                ? tickerData[current].so3
                : scrip.so3;
              scrip.so4 = tickerData[current].so4
                ? tickerData[current].so4
                : scrip.so4;
              scrip.so5 = tickerData[current].so5
                ? tickerData[current].so5
                : scrip.so5;

              scrip.sq1 = tickerData[current].sq1
                ? tickerData[current].sq1
                : scrip.sq1;
              scrip.sq2 = tickerData[current].sq2
                ? tickerData[current].sq2
                : scrip.sq2;
              scrip.sq3 = tickerData[current].sq3
                ? tickerData[current].sq3
                : scrip.sq3;
              scrip.sq4 = tickerData[current].sq4
                ? tickerData[current].sq4
                : scrip.sq4;
              scrip.sq5 = tickerData[current].sq5
                ? tickerData[current].sq5
                : scrip.sq5;

              scrip.tsq = tickerData[current].tsq
                ? tickerData[current].tsq
                : scrip.tsq;

              scrip.v = tickerData[current].v ? tickerData[current].v : scrip.v;

              scrip.lc = tickerData[current].lc
                ? tickerData[current].lc
                : scrip.lc;
              scrip.uc = tickerData[current].uc
                ? tickerData[current].uc
                : scrip.uc;

              scrip.ltt = tickerData[current].ltt
                ? tickerData[current].ltt
                : scrip.ltt;
              scrip.ltq = tickerData[current].ltq
                ? tickerData[current].ltq
                : scrip.ltq;
              scrip.avg_prc = tickerData[current].ap
                ? tickerData[current].ap
                : scrip.avg_prc;
              scrip.oi = tickerData[current].oi ? tickerData[current].oi : "0";
              scrip.yh = tickerData[current]["52h"]
                ? tickerData[current]["52h"]
                : scrip.yh;

              scrip.yl = tickerData[current]["52l"]
                ? tickerData[current]["52l"]
                : scrip.yl;
            }
          });
        }
      }
    },

    updateWatchData() {
      let json = {
        value: this.watchlistData,
        from: "listing",
      };
      this.$emit("updateWatchData", json);
    },
  },
  created() {
    window.watch = {
      callback: this.wsWatch,
    };
    window.addwatch = {
      callback: this.updateWatchData,
    };
  },
};
</script>
<style>
/* font-resize class binding */
[data-font-size="S"] {
  font-size: 12px !important;
}

[data-font-size="M"] {
  font-size: 14px !important;
}

[data-font-size="L"] {
  font-size: 16px !important;
}

[data-font-size="XL"] {
  font-size: 18px !important;
}

[sub-data-font-size="S"] {
  font-size: 10px !important;
}

[sub-data-font-size="M"] {
  font-size: 11px !important;
}

[sub-data-font-size="L"] {
  font-size: 12px !important;
}
.currentToken {
  display: none;
}
</style>
