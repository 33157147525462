<template>
  <v-sheet
    transition="fade-transition"
    tile
    class="searchContent"
    :class="$vuetify.theme.dark ? 'bg-blak' : 'bg-white'"
  >
    <ul class="pl-0 overflow-x-hidden" id="searchlist">
      <li
        v-if="isResent && this.showRecentSearch && recentSearchData.length > 0"
        class="searchlist px-2 py-3 body-2 border-bottom-light d-flex align-center  listParent justify-space-between"
      >
        <div>Recent Search</div>
        <div class="cursorPointer" @click="recentClear()">Clear all</div>
      </li>
      <li
        class="searchlist px-2 py-3 body-2 border-bottom-light d-flex align-center listParent justify-space-between"
        v-for="(item, index) in getData()"
        :key="item.token"
        :id="`${index}_search_scrip`"
      >
        <div class="d-flex">
          <a
            @click="
              isResent
                ? onRecentClick(item)
                : item.checked
                ? onClickOutside()
                : handleMultipleClick(item, item.checked ? 'del' : 'add', false)
            "
            class="ml-1"
            :class="[getHoverClass(item, 'a'), getFontSize(14)]"
            :id="`${index}_search_scrip_name`"
          >
            {{ isResent ? item : item.formattedInsName }} &nbsp;
          </a>
          <div class="hoverbtnsList" v-if="!isResent">
            <div class="d-flex justify-end">
              <div class="d-flex align-center absolute">
                <v-tooltip
                  top
                  :color="
                    $vuetify.theme.dark
                      ? 'darktoolTipColor toolTipTop darkTooltipTop'
                      : 'lighttoolTipColor toolTipTop lightTooltipTop'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      :id="`${index}_scrip_buy`"
                      class="hoverBtn mr-2 buy"
                      v-if="
                        item.segment && item.segment != 'INDEX'
                      "
                      @click="
                        callOrderWindowByContract(
                          item,
                          'buy',
                          false,
                          'mkWatch',
                          false,
                          false,
                          false,
                          false
                        )
                      "
                      v-bind="attrs"
                      v-on="on"
                      >B</span
                    >
                  </template>
                  <span>Buy</span>
                </v-tooltip>

                <v-tooltip
                  top
                  :color="
                    $vuetify.theme.dark
                      ? 'darktoolTipColor toolTipTop darkTooltipTop'
                      : 'lighttoolTipColor toolTipTop lightTooltipTop'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      :id="`${index}_scrip_sell`"
                      class="hoverBtn mr-2 sell"
                      v-if="
                        item.segment && item.segment != 'INDEX'
                      "
                      @click="
                        callOrderWindowByContract(
                          item,
                          'sell',
                          false,
                          'mkWatch',
                          false,
                          false,
                          false,
                          false
                        )
                      "
                      v-bind="attrs"
                      v-on="on"
                      >S</span
                    >
                  </template>
                  <span>Sell</span>
                </v-tooltip>
                <v-tooltip
                  top
                  :color="
                    $vuetify.theme.dark
                      ? 'darktoolTipColor toolTipTop darkTooltipTop'
                      : 'lighttoolTipColor toolTipTop lightTooltipTop'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      :class="[getHoverClass(item, 'add'), getFontSize(16)]"
                      class="hoverBtn mr-2"
                      @click="
                        item.checked
                          ? onClickOutside()
                          : handleMultipleClick(
                              item,
                              item.checked ? 'del' : 'add',
                              false,
                              watchData?.length
                            )
                      "
                      v-bind="attrs"
                      v-on="on"
                      :id="`${index}_search_scrip_check`"
                    >
                      <span v-if="item.checked">&#10004; </span>
                      <span v-else> &#43; </span>
                    </span>
                  </template>
                  <span>{{ item.checked ? "Added" : "Add" }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
        <button
          class="pa-0 rounded min-width-40"
          :class="[
            item.exchange == 'NSE'
              ? 'buycolor'
              : item.exchange == 'BSE'
              ? 'sellcolor'
              : item.exchange == 'NFO'
              ? 'cancelled-msg'
              : 'cancelled-msg',
            getFontSize(10),
          ]"
          depressed
          min-width="36"
          max-width="36"
          height="26"
          :id="`${index}_search_scrip_ex`"
        >
          {{ item.exchange }}
        </button>
      </li>
    </ul>
  </v-sheet>
</template>
<script>
import { mapState } from "vuex";
import commonFunctions from "@/mixins/commonFunctions";
import errorHandling from '../../handleError/errHandling.js'
export default {
  name: "search",
  data() {
    return {
      isResent: false,
    };
  },
  computed: {
    ...mapState("marketWatch", [
      "searchList",
      "watchlistData",
      "watchList",
      "tab",
      "recentSearchData",
      "showRecentSearch",
    ]),
  },
  props: {
    watchData: Array,
  },
  mixins: [commonFunctions],
  methods: {
    async addorDeleteScrip(data, type, isSingle, length) {
      // this.openPanel(-1, "item");
      let json = {
        userId: this.getUCC,

        scripData: [
          {
            exch: data.exchange,
            token: data.token,
          },
        ],
      };
      if (type == "del") {
        await this.$store.dispatch("marketWatch/deleteSearchList", {
          json,
          data,
        });
      } else if (type == "add" && length < 30) {
        await this.$store.dispatch("marketWatch/addSearchList", { json, data });
      } else if (type == "add" && length >= 30) {
        errorHandling.toaster(
          "",
          "danger",
          "Can't add more than 30 scrips.Use another tab",
          3000,
          "top-right",
          " ",
          " "
        );
      }
      if (isSingle) {
        this.onClickOutside();
      }
      this.$store.dispatch("marketWatch/changeWatchList").finally(() => {
        this.emitWatch();
      });
    },
    emitWatch() {
      let json = {
        value: this.watchlistData,
        from: "searchList",
      };
      this.$emit("updatedWatch", json);
    },
    async handleMultipleClick(data, type, isSingle, length) {
      clearTimeout(this.addScripTimer);
      this.addScripTimer = setTimeout(() => {
        this.addorDeleteScrip(data, type, isSingle, length);
      }, 500);
    },
    onClickOutside() {
      this.search = "";
      this.$store.commit("marketWatch/setSearchList", []);
      clearTimeout(this.timerId);
      clearTimeout(this.addScripTimer);
    },
    getHoverClass(item, key) {
      var classcss;
      if (key == "a") {
        if (item.checked) {
          classcss = this.$vuetify.theme.dark ? "white--text" : "color-282828";
        } else {
          classcss = this.$vuetify.theme.dark ? "white--text" : "color-282828";
        }
        return classcss;
      }
      if (key == "add") {
        if (item.checked) {
          classcss = this.$vuetify.theme.dark ? "darkHover" : "";
        } else {
          classcss = key;
        }
        return classcss;
      }
    },

    getData() {
      this.isResent =
        this.searchList.length > 0 && !this.showRecentSearch ? false : true;
      return this.searchList.length > 0
        ? this.searchList
        : this.showRecentSearch
        ? this.recentSearchData
        : [];
    },

    onRecentClick(val) {
      let json = {
        value: val,
        from: "recentList",
      };
      this.$emit("updatedWatch", json);
    },

    recentClear() {
      let json = {
        isUpdate: true,
        data: [],
      };
      this.$store.commit("marketWatch/setRecentSearchData", json);
    },
  },
};
</script>
<style></style>
