import errorHandling from "../../handleError/errHandling";
import { commonService } from "@/services/common.service";
import router from "@/router";
import formula from "@/mixins/formula";
const mkWatch = {
  namespaced: true,

  state: {
    infoDialog: false,
    searchList: [],
    securityInfoData: [],
    alertDialog: false,
    loading: false,
    alertLoader: false,
    authDialog: false,
    watchList: [],
    tab: 0,
    preTab: 0,
    authLoader: false,
    technicalData: [],

    // new states
    searchLoader: false,
    iniWatchList: [],
    watchlistData: [],
    mwSearchList: [],
    watchListLoader: false,
    noInfoData: false,
    predefMenu: [],
    predefData: [],
    depthIndex: -1,
    recentSearchData: [],
    showRecentSearch: false,
    tableFontSize: "M",
  },

  mutations: {
    resetState(state) {
      state.noInfoData = false;
      state.infoDialog = false;
      state.searchList = [];
      state.securityInfoData = [];
      state.alertDialog = false;
      state.loading = false;
      state.alertLoader = false;
      state.authDialog = false;
      state.watchList = [];
      state.tab = 0;
      state.authLoader = false;
      state.technicalData = [];
      state.searchLoader = false;
      state.iniWatchList = [];
      state.watchlistData = [];
      state.mwSearchList = [];
      state.watchListLoader = false;
    },
    setDepthIndex(state, payload) {
      state.depthIndex = payload;
    },
    noInfoData(state, payload) {
      state.noInfoData = payload;
    },
    setWatchListLoader(state, payload) {
      state.watchListLoader = payload;
    },
    handleInfoDialog(state, payload) {
      state.infoDialog = payload;
    },

    setSearchList(state, data) {
      state.searchList = data;
    },
    setSecurityInfo(state, data) {
      state.securityInfoData = data;
    },
    setAlertDialog(state, data) {
      state.alertDialog = data;
    },
    setAuthDialog(state, payload) {
      state.authDialog = payload;
    },

    setWatchList(state, payload) {
      state.watchList = payload;

      this.dispatch("marketWatch/checkSocketStatus");
    },
    setTab(state, payload) {
      state.tab = payload;
    },
    setPredefTab(state, payload) {
      state.preTab = payload;
      localStorage.setItem("preTab", JSON.stringify(payload));
    },
    setAuthLoader(state, payload) {
      state.authLoader = payload;
    },
    setTechnicalData(state, payload) {
      state.technicalData = payload;
    },
    setTableFontSize(state, payload) {
      state.tableFontSize = payload;
    },
    // new commits
    setSearchLoader(state, payload) {
      state.searchLoader = payload;
    },
    setIniWatchList(state, payload) {
      state.iniWatchList = payload;
    },
    setWatchListData(state, payload) {
      state.watchlistData = payload;

      this.dispatch("marketWatch/checkSocketStatus");
    },
    setMwSearchList(state, payload) {
      state.mwSearchList = payload;
    },
    setPreDefMenu(state, payload) {
      state.predefMenu = payload;
    },
    setPredefData(state, payload) {
      state.predefData = payload;
    },
    setInitPredefData(state, payload) {
      state.predefList = payload;
    },
    setRecentSearchData(state, payload) {
      if (payload.isUpdate == true) {
        state.recentSearchData = payload.data;
      } else {
        state.recentSearchData.push(payload.data);
      }
      localStorage.setItem(
        "recentSeach",
        JSON.stringify(state.recentSearchData)
      );
    },
    setShowRecentSearch(state, payload) {
      state.showRecentSearch = payload;
    },
  },

  actions: {
    async getwatchList({ dispatch, commit, rootGetters }) {
      commit("setWatchListLoader", true);

      let json = {
        defaultMw: true,
        source: "WEB",
        preDef: true,
        advFlag: true,
        lstsFlag: true,
      };
      await commonService
        .getMwList(json)
        .then(
          async (resp) => {
            if (
              resp.status == 200 &&
              (resp.data.status == "Not ok" || resp.data.status == "Ok")
            ) {
              let mwList = resp.data.result.filter((pre) => !pre.preDef);
              let predefList = resp.data.result.filter(
                (pre) => pre?.preDef == "1"
              );

              let predefMenu = [];

              resp.data.result.forEach((el) => {
                el?.preDef == "1" ? predefMenu.push(el.mwName) : "";
              });

              commit("setIniWatchList", mwList);
              commit("setPreDefMenu", predefMenu);
              commit("setInitPredefData", predefList);

              await dispatch("setKeys", {
                data: mwList,
                where: "initial",
                action: "initial",
              });
            }
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {
          commit("setWatchListLoader", false);
        });
    },

    async setKeys(
      { state, commit, dispatch, rootGetters, rootState },
      payload
    ) {
      let zeroVal = "0.00";
      let tempWatchList = [];
      let tempWatchlistData;

      tempWatchList = state.watchList;
      tempWatchlistData = tempWatchList[state.tab]?.scrips
        ? tempWatchList[state.tab]?.scrips
        : [];

      let ObjList = {};
      let tokenList = [];
      let tempPanel = [];
      if (payload.where == "initial") {
        for (let list of payload.data) {
          let Obj = {};
          let tokenLst = [];
          if (list.scrips) {
            for (let scrip of list.scrips) {
              tempPanel.push(list.scrips.indexOf(scrip));
              scrip.depth = false;
              scrip = await updateValues(scrip, zeroVal, []);
              Obj[scrip.token] = scrip;
              tokenLst.push(scrip.token);
            }
            list.panel = tempPanel;
          } else {
            list.scrips = [];
            list.panel = [];
          }
          list.Obj = Obj;
          list.tokenList = tokenLst;
        }
      }
      if (payload.where == "add") {
        ObjList = tempWatchList[state.tab].Obj;
        tokenList = tempWatchList[state.tab].tokenList;
        for (let scrip of payload.data) {
          scrip.depth = false;
          scrip = await updateValues(scrip, zeroVal, []);
          ObjList[scrip.token] = scrip;
          tokenList.push(scrip.token);
        }
        tempWatchList[state.tab].scrips = [
          ...tempWatchlistData,
          ...payload.data,
        ];
        tempWatchList[state.tab].Obj = ObjList;
        tempWatchList[state.tab].tokenList = tokenList;
      }
      if (payload.where == "del") {
        ObjList = tempWatchList[state.tab].Obj;
        const index = tempWatchList[state.tab].scrips.findIndex(
          (std) => std.token === payload.data.token
        );
        if (index != -1) {
          delete ObjList[tempWatchList[state.tab].scrips[index].token];
          tempWatchList[state.tab].Obj = ObjList;
          tempWatchList[state.tab].scrips.splice(index, 1);
          tempWatchList[state.tab].tokenList.splice(
            tempWatchList[state.tab].tokenList.indexOf(payload.data.token),
            1
          );
        }
      }
      if (payload.where == "add" || payload.where == "del") {
        commit("setWatchList", tempWatchList);
        if (payload.where == "add" && rootState.preferenceWatch == "1") {
          let arr = state?.watchList[state?.tab]?.scrips;
          arr.unshift(arr.pop());
          commit("setWatchList", tempWatchList);
          dispatch("sortList");
        }
      }
      if (payload.where == "initial") {
        commit("setWatchList", payload.data);
      }

      // if (payload.type == "pre" && payload.where == "initial") {
      //   commit("setPredefData", payload.data);
      // } else if (payload.type == "wat" && payload.where == "initial") {
      //   commit("setWatchList", payload.data);
      // }
      await dispatch("checkSocketStatus");
    },

    async changeWatchList({ state, commit, dispatch }, payload) {
      let data = [];

      data =
        state.watchList.length > 0 ? state?.watchList[state?.tab]?.scrips : [];

      await commit("setWatchListData", data);
    },

    async getSearchList({ state, commit, dispatch, rootState }, payload) {
      if (payload.where == "mwatch") {
        commit("setSearchLoader", true);
      } else if (payload.where == "basket") {
        commit("basket/setSearchLoader", true, { root: true });
      } else if (payload.where == "alert") {
        commit("basket/setSearchLoader", true, { root: true });
      } else if (payload.where == "gtt") {
        commit("gtt/setSearchLoader", true, { root: true });
      }

      let json = {
        exchange:
          payload.where == "mwatch"
            ? ["All"]
            : ["CDS", "MCX", "NFO", "NSE", "BSE", "BFO"],
        symbol: payload.value,
      };

      await commonService
        .getSearchList(json)
        .then(
          (response) => {
            if (
              response.status == 200 &&
              response.data.status == "Ok" &&
              response.data.message == "Success" &&
              response.data.result &&
              response.data.result.length > 0
            ) {
              state.showRecentSearch = false;
              for (let scrip of response.data.result) {
                if (state.watchList[state.tab]?.scrips) {
                  for (let list of state.watchList[state.tab]?.scrips) {
                    if (
                      scrip.token == list.token &&
                      scrip.exchange == list.exchange &&
                      !scrip.checked
                    ) {
                      scrip.checked = true;
                    }
                    if (scrip.token != list.token && !scrip.checked) {
                      scrip.checked = false;
                    }
                  }
                } else {
                  scrip.checked = false;
                }
              }
              if (payload.where == "alert") {
                commit("alert/setSearchListForAlert", response.data.result, {
                  root: true,
                });
              } else if (payload.where == "basket") {
                let basketArray = response.data.result.filter(
                  (el) => el.segment != "INDEX"
                );
                commit("basket/setSearchListForBasket", basketArray, {
                  root: true,
                });
              } else if (payload.where == "gtt") {
                let gttArray = response.data.result.filter(
                  (el) => el.segment != "INDEX"
                );
                commit("gtt/setSearchListForGtt", gttArray, {
                  root: true,
                });
              } else {
                commit("setSearchList", response.data.result);
              }
            } else {
              commit("setSearchList", []);
              commit("alert/setSearchListForAlert", [], { root: true });
              commit("basket/setSearchListForBasket", [], { root: true });
            }
          },
          (err) => {
            commit("setSearchLoader", false);
            errorHandling.errLog(err);
          }
        )

        .finally(() => {
          commit("setSearchLoader", false);
          commit("gtt/setSearchLoader", false, { root: true });
        });
    },

    async addSearchList({ state, commit, dispatch }, payload) {
      (payload.json.mwId = state.watchList[state.tab]?.mwId),
        await commonService
          .addScripList(payload.json)
          .then(
            async (resp) => {
              if (
                resp.status == 200 &&
                resp.data.status == "Ok" &&
                resp.data.message == "Success"
              ) {
                state.searchList.forEach((el) => {
                  if (payload.data.token == el.token) {
                    return (el.checked = true);
                  }
                });

                if(payload.isToaster){
                  errorHandling.toaster(
                    "",
                    "success",
                    'Scrip added successfully',
                    4500,
                    "",
                    " ",
                    " "
                  );
                }
                //  dispatch("getwatchList");

                await commit("setSearchList", state.searchList);

                await dispatch("setKeys", {
                  data: resp.data.result,
                  where: "add",
                  action: "change",
                });
              } else {
                errorHandling.toaster(
                  "Failed",
                  "danger",
                  resp.data.message,
                  4500,
                  "",
                  " ",
                  " "
                );
              }
            },
            (err) => {
              errorHandling.errLog(err);
            }
          )
          .finally(() => {});
    },

    async deleteSearchList({ state, dispatch, commit }, payload) {
      commit("setSearchLoader", true);
      (payload.json.mwId = state.watchList[state.tab]?.mwId),
        commonService
          .deleteScripList(payload.json)
          .then(
            async (resp) => {
              if (
                resp.status == 200 &&
                resp.data.status == "Ok" &&
                resp.data.message == "Success"
              ) {
                await dispatch("setKeys", {
                  data: payload.data,
                  where: "del",
                  action: "change",
                });
              } else {
                errorHandling.toaster(
                  "Failed",
                  "danger",
                  resp.data.message,
                  4500,
                  "",
                  " ",
                  " "
                );
              }
            },
            (err) => {
              errorHandling.errLog(err);
            }
          )
          .finally(() => {
            commit("setSearchLoader", false);
          });
    },

    async sortList({ state, rootGetters }) {
      var sortArray = [];
      for (let i of state.watchList[state.tab].scrips) {
        let temp = {
          exch: i.exchange,
          token: i.token,
          sortingOrder: state.watchList[state.tab].scrips.indexOf(i),
        };
        sortArray.push(temp);
      }
      var getSortObj = {
        mwId: Number(state.watchList[state.tab].mwId),
        mwName: state.watchList[state.tab].mwName,
        userId: rootGetters["login/getCurrentUserId"],
        scripData: sortArray,
      };

      await commonService
        .sortMwList(getSortObj)
        .then(
          (response) => {
            if (
              response.data["stat"] == "Not_Ok" &&
              response.data["emsg"] == "Session Expired"
            ) {
              errorHandling.sessionExpire();
            }
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {});
    },

    //securityInfo
    async getSecurityInfo({ commit, rootGetters, state }, payload) {
      state.loading = true;
      let json = {
        exch: payload.exchange,
        token: payload.token,
      };
      await commonService
        .getSecurityInfo(json)
        .then(
          (response) => {
            if (response.status == 200 && response.data.status != "Not ok") {
              commit("noInfoData", false);
              response.data.result?.length > 0
                ? commit("setSecurityInfo", response.data.result)
                : commit("noInfoData", true);
            } else if (
              response.status == 200 &&
              response.data.status == "Not ok"
            ) {
              commit("noInfoData", true);
              errorHandling.toaster(
                "",
                "danger",
                "No data available",
                5000,
                "bottom-right",
                "",
                ""
              );
            } else if (
              response.data.status == "Not_Ok" &&
              response.data.emsg == "Session Expired"
            ) {
              errorHandling.sessionExpire();
            }
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {
          state.loading = false;
        });
    },

    checkSocketStatus({ state, rootGetters, dispatch }, payload) {
      if (
        rootGetters["wsConnection/getConnectionStatue"] &&
        router.currentRoute.path != "/popout"
      ) {
        let socketData = state.watchlistData;
        var tempData = {
          data: socketData ? socketData : [],
          where: "mkWatch",
        };

        dispatch("wsConnection/websocketSubscription", tempData, {
          root: true,
        });
      }
    },

    setHoldingsFlag(
      { state, commit, dispatch, rootGetters, rootState },
      payload
    ) {
      for (let tab = 0; tab < 5; tab++) {
        if (state.watchList?.length > 0 && state.watchList[tab].scrips) {
          for (let watchList of state.watchList[tab].scrips) {
            for (let hold of rootState.holdings.holdingList) {
              if (hold.ex == watchList.ex && hold.token == watchList.token) {
                watchList.holFlag = 1;
                watchList.holdQty = hold.SellableQty;
                break;
              }
              if (hold.ex !== watchList.ex && hold.token !== watchList.token) {
                watchList.holFlag = 0;
              }
            }
          }
        }
      }
    },
  },

  getters: {
    getAlertDialog: (state) => {
      return state.alertDialog;
    },
    getMwList: (state) => {
      return state.watchlistData;
    },
    getSearchLoader: (state) => state.searchLoader,
  },
};
export default mkWatch;

async function updateValues(scrip, zeroVal, holdList) {
  if (scrip.segment && scrip.segment.includes('_idx')) {
    scrip.exchIndex = `${scrip.exchange}::index`;
  }

  scrip.pdc = scrip.pdc ? scrip.pdc : 0;

  let tickerData = window.tickers;
  let key = `${scrip.exchange}|${scrip.token}`;
  scrip.o =
    tickerData && tickerData[key] && tickerData[key].o
      ? tickerData[key].o
      : zeroVal;
  scrip.h =
    tickerData && tickerData[key] && tickerData[key].h
      ? tickerData[key].h
      : zeroVal;
  scrip.l =
    tickerData && tickerData[key] && tickerData[key].l
      ? tickerData[key].l
      : zeroVal;
  scrip.c =
    tickerData && tickerData[key] && tickerData[key].c
      ? tickerData[key].c
      : zeroVal;

  scrip.pdc == 0
    ? (scrip.pdc = tickerData
        ? tickerData && tickerData[key] && tickerData[key].c
        : scrip.pdc)
    : "0.00";

  scrip.ltp =
    tickerData && tickerData[key] && tickerData[key].lp
      ? tickerData[key].lp
      : scrip.pdc
      ? scrip.pdc
      : "0.00";

  scrip.chg = formula.methods.getChg(scrip.ltp,scrip.c,scrip.exchange)
  scrip.pc =
    tickerData && tickerData[key] && tickerData[key].pc
      ? tickerData[key].pc
      : zeroVal;

  scrip.bp1 =
    tickerData && tickerData[key] && tickerData[key].bp1
      ? tickerData[key].bp1
      : zeroVal;
  scrip.bp2 =
    tickerData && tickerData[key] && tickerData[key].bp2
      ? tickerData[key].bp2
      : zeroVal;
  scrip.bp3 =
    tickerData && tickerData[key] && tickerData[key].bp3
      ? tickerData[key].bp3
      : zeroVal;
  scrip.bp4 =
    tickerData && tickerData[key] && tickerData[key].bp4
      ? tickerData[key].bp4
      : zeroVal;
  scrip.bp5 =
    tickerData && tickerData[key] && tickerData[key].bp5
      ? tickerData[key].bp5
      : zeroVal;
  scrip.bo1 =
    tickerData && tickerData[key] && tickerData[key].bo1
      ? tickerData[key].bo1
      : zeroVal;
  scrip.bo2 =
    tickerData && tickerData[key] && tickerData[key].bo2
      ? tickerData[key].bo2
      : zeroVal;
  scrip.bo3 =
    tickerData && tickerData[key] && tickerData[key].bo3
      ? tickerData[key].bo3
      : zeroVal;
  scrip.bo4 =
    tickerData && tickerData[key] && tickerData[key].bo4
      ? tickerData[key].bo4
      : zeroVal;
  scrip.bo5 =
    tickerData && tickerData[key] && tickerData[key].bo5
      ? tickerData[key].bo5
      : zeroVal;
  scrip.bq1 =
    tickerData && tickerData[key] && tickerData[key].bq1
      ? tickerData[key].bq1
      : zeroVal;
  scrip.bq2 =
    tickerData && tickerData[key] && tickerData[key].bq2
      ? tickerData[key].bq2
      : zeroVal;
  scrip.bq3 =
    tickerData && tickerData[key] && tickerData[key].bq3
      ? tickerData[key].bq3
      : zeroVal;
  scrip.bq4 =
    tickerData && tickerData[key] && tickerData[key].bq4
      ? tickerData[key].bq4
      : zeroVal;
  scrip.bq5 =
    tickerData && tickerData[key] && tickerData[key].bq5
      ? tickerData[key].bq5
      : zeroVal;

  scrip.tbq =
    tickerData && tickerData[key] && tickerData[key].tbq
      ? tickerData[key].tbq
      : zeroVal;

  scrip.sp1 =
    tickerData && tickerData[key] && tickerData[key].sp1
      ? tickerData[key].sp1
      : zeroVal;
  scrip.sp2 =
    tickerData && tickerData[key] && tickerData[key].sp2
      ? tickerData[key].sp2
      : zeroVal;
  scrip.sp3 =
    tickerData && tickerData[key] && tickerData[key].sp3
      ? tickerData[key].sp3
      : zeroVal;
  scrip.sp4 =
    tickerData && tickerData[key] && tickerData[key].sp4
      ? tickerData[key].sp4
      : zeroVal;
  scrip.sp5 =
    tickerData && tickerData[key] && tickerData[key].sp5
      ? tickerData[key].sp5
      : zeroVal;

  scrip.so1 =
    tickerData && tickerData[key] && tickerData[key].so1
      ? tickerData[key].so1
      : zeroVal;
  scrip.so2 =
    tickerData && tickerData[key] && tickerData[key].so2
      ? tickerData[key].so2
      : zeroVal;
  scrip.so3 =
    tickerData && tickerData[key] && tickerData[key].so3
      ? tickerData[key].so3
      : zeroVal;
  scrip.so4 =
    tickerData && tickerData[key] && tickerData[key].so4
      ? tickerData[key].so4
      : zeroVal;
  scrip.so5 =
    tickerData && tickerData[key] && tickerData[key].so5
      ? tickerData[key].so5
      : zeroVal;

  scrip.sq1 =
    tickerData && tickerData[key] && tickerData[key].sq1
      ? tickerData[key].sq1
      : zeroVal;
  scrip.sq2 =
    tickerData && tickerData[key] && tickerData[key].sq2
      ? tickerData[key].sq2
      : zeroVal;
  scrip.sq3 =
    tickerData && tickerData[key] && tickerData[key].sq3
      ? tickerData[key].sq3
      : zeroVal;
  scrip.sq4 =
    tickerData && tickerData[key] && tickerData[key].sq4
      ? tickerData[key].sq4
      : zeroVal;
  scrip.sq5 =
    tickerData && tickerData[key] && tickerData[key].sq5
      ? tickerData[key].sq5
      : zeroVal;

  scrip.tsq =
    tickerData && tickerData[key] && tickerData[key].tsq
      ? tickerData[key].tsq
      : zeroVal;

  scrip.v =
    tickerData && tickerData[key] && tickerData[key].v
      ? tickerData[key].v
      : zeroVal;
  scrip.lc =
    tickerData && tickerData[key] && tickerData[key].lc
      ? tickerData[key].lc
      : zeroVal;
  scrip.uc =
    tickerData && tickerData[key] && tickerData[key].uc
      ? tickerData[key].uc
      : zeroVal;

  scrip.ltt =
    tickerData && tickerData[key] && tickerData[key].ltt
      ? tickerData[key].ltt
      : zeroVal;
  scrip.ltq =
    tickerData && tickerData[key] && tickerData[key].ltq
      ? tickerData[key].ltq
      : zeroVal;
  scrip.avg_prc =
    tickerData && tickerData[key] && tickerData[key].ap
      ? tickerData[key].ap
      : zeroVal;
  scrip.yh =
    tickerData && tickerData[key] && tickerData[key]["52h"]
      ? tickerData[key]["52h"]
      : zeroVal;
  scrip.yl =
    tickerData && tickerData[key] && tickerData[key]["52l"]
      ? tickerData[key]["52l"]
      : zeroVal;

  return scrip;
}
