import router from "../router/index";
import store from "../store/index";
import Vue from "vue";
// import modCommon from './common'
const errorHandling = new Vue({
  data() {
    return {
      nState: null,
      errLogCounter: 0,
      counter: 0,
    };
  },
  methods: {
    errLog(msg) {
      if (
        msg.response.status >= 400 &&
        msg.response.status < 500 &&
        store.state.counter == 0 &&
        this.errLogCounter == 0
      ) {
        if (msg.response.status != 404) {
          this.errLogCounter = 1;
          if (msg.response.data) {
            router.currentRoute.path == "/cdslmobile" &&
            router.currentRoute.query.sUrl &&
            router.currentRoute.query.fUrl
              ? ""
              : this.toaster(
                  "",
                  "danger",
                  msg.response.data,
                  3000,
                  "top-right",
                  " ",
                  " "
                );
          }
          router.currentRoute.path == "/cdslmobile" &&
          router.currentRoute.query.sUrl &&
          router.currentRoute.query.fUrl
            ? ""
            : store.commit("setCounterIncrease", 1);
        }
        msg.response.status == 404
          ? this.toaster(
              "",
              "danger",
              "We'II Be Back Soon",
              3000,
              "top-right",
              " ",
              " "
            )
          : this.resetLocal(false);
      } else if (msg.response.status >= 500) {
        this.toaster(
          "",
          "danger",
          "Something went wrong. Please try again later",
          3000,
          "top-right",
          " ",
          " "
        );
      } else if (msg.response.status == 401) {
        this.sessionExpire();
      }
    },

    resetLocal(isClear) {
      let ucc = JSON.parse(localStorage.getItem("UCC"));
      let isExist = JSON.parse(localStorage.getItem("isExistUser"));
      let font =
        localStorage.getItem("font") &&
        localStorage.getItem("font") != "undefined"
          ? localStorage.getItem("font")
          : "s";

      store.commit("basket/setIsRename", false, { root: true });
      store.commit("basket/setSearchListForBasket", false, { root: true });
      store.commit("basket/setIsConfirmDialog", false, { root: true });
      store.commit("position/handleConvertDialog", false, { root: true });

      // reset State
      store.dispatch("wsConnection/websocketClose");
      store.commit("position/resetState");
      store.commit("orderWindow/resetState");
      store.commit("marketWatch/resetState");
      store.commit("gtt/resetState");
      store.commit("header/resetState");
      store.commit("login/resetState");
      store.commit("alert/resetState");
      store.commit("appsStore/resetState");
      store.commit("chart/resetState");
      store.commit("pref/resetState");
      store.commit("funds/resetState");
      store.commit("holdings/resetState");
      store.commit("home/resetState");
      store.commit("order/resetState");
      store.commit("basket/resetState");
      store.commit("resetState");
      localStorage.clear();

      if (!isClear) {
        isExist ? store.commit("login/setIsExistUser", isExist) : "";
        if (ucc) {
          store.dispatch("login/verifyClient", { mobileNo: ucc });
          store.commit("login/setUCC", ucc);
          localStorage.setItem("currentUser", JSON.stringify(ucc));
        }
      }
      router.currentRoute.path !== "/" ? router.push("/").catch(() => {}) : "";
      store.commit("setSessionCounter", 0); //
      store.commit("setCounterIncrease", 0);
      store.commit("setGlobalFont", font);
      store.commit(
        "marketWatch/setTableFontSize",
        font.toString().toUpperCase()
      );
    },

    sessionExpire() {
      if (store.state.sessionCounter == 0 && this.counter == 0) {
        this.counter = 1;
        router.currentRoute.path == "/cdslmobile" &&
        router.currentRoute.query.sUrl &&
        router.currentRoute.query.fUrl
          ? ""
          : store.commit("setSessionCounter", 1);
        router.currentRoute.path == "/cdslmobile" &&
        router.currentRoute.query.sUrl &&
        router.currentRoute.query.fUrl
          ? ""
          : this.toaster(
              "",
              "danger",
              "Your session has been invalidated. Kindly re-login",
              3000,
              "top-right",
              " ",
              " "
            );
        this.resetLocal(false);
      }
    },

    toaster(title, type, values, duration, position, status, comment) {
      store.state.positiontoaster = position ? position : "top-right";
      let options = {
        type: type,
        message: values,
        status: status,
        duration: duration,
        comment: comment,
      };
      title == "" ? "" : (options.title = title);
      this.$notification.open(options);
      this.nState = JSON.stringify(options, null, 2);
    },
    passData(amount, recipt_Id, resp) {
      let json = {
        amount: amount,
        currency: "INR",
        receipt: recipt_Id,
        razorpayOrderId: resp.razorpay_order_id,
        razorpayPaymentId: resp.razorpay_payment_id,
        razorpaySignature: resp.razorpay_signature,
      };
      store.dispatch("funds/checkVerifyPayment", json);
    },
  },
});

export default errorHandling;
